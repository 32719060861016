export const ApiURLConstants = {
     /** Session */
     PostUserLogin_URL: 'v1/Admin/PostUserLogin',
     GetUserLogout_URL: 'v1/Public/GetUserLogout',
     Refresh_URL: 'v1/Public/Refresh',
     GetServerTime_URL: 'v1/Public/ServerTime',
     GetConfig_URL: 'v1/Public/GetConfig',

     /**Tasks */
     GetTaskList_URL: 'v1/AdminTasks/PostGetTasks',
     GetTaskDetail_URL: 'v1/AdminTasks/GetTask',
     PutAssignTask_URL: 'v1/AdminTasks/PutAssignTask',
     PostAddAdditionalCostsTask_URL: 'v1/AdminTasks/PostAddAdditionalCostsTask',
     PostAddActivityNote_URL: 'v1/AdminTasks/PostAddActivityNote',
     PostAddTaskAttachments_URL: 'v1/AdminTasks/PostAddTaskAttachments',
     GetTaskAttachmentFile_URL: 'v1/AdminTasks/GetTaskAttachmentFile',
     PutUpdateTaskStatus_URL: 'v1/AdminTasks/PutUpdateTaskStatus',
     GetAdminUsersForTaskListDropdown_URL: 'v1/AdminTasks/GetAdminUsersForTaskListDropdown',
     GetJobSummaryDetails_URL : 'v1/Jobs/GetJobSummaryDetails',
     DeleteTaskAttachment_URL : 'v1/AdminTasks/DeleteTaskAttachment',

     /** Members */
     GetConfigObject_URL: 'v1/Member/GetConfigObject',
     DeleteMemberBlocks_URL: 'v1/Member/DeleteMemberBlocks',
     PutAddMembersBlock_URL: 'v1/Member/PutAddMembersBlock',
     PostAddMemberFavourite_URL: 'v1/Member/PostAddMemberFavourite',
     PutUpdateMemberFavourite_URL: 'v1/Member/PutUpdateMemberFavourite',
     DeleteMemberFavourites_URL: 'v1/Member/DeleteMemberFavourites',
     GetMemberFavourites_URL: 'v1/Member/GetMemberFavourites',
     GetPartnersForDropdown_URL: 'v1/Member/GetPartnersForDropdown',
     PostAddConfigObject_URL: 'v1/Member/PostAddConfigObject',
     PutUpdateConfigObject_URL: 'v1/Member/PutUpdateConfigObject',
     GetSupplyingMembersByUserNameAndServiceType_URL: 'v1/Member/GetSupplyingMembersByUserNameAndServiceType',
     GetMembers_URL: 'v1/Member/GetMembers',
     ExportMembersToCsv_URL: 'v1/Member/ExportMembersToCSV',
     PostAddMember_URL: 'v1/Member/PostAddMember',
     PutUpdateMember_URL: 'v1/Member/PutUpdateMember',
     GetMember_URL: 'v1/Member/GetMember',
     GetNewMembers_URL: 'v1/Member/GetNewMembers',
     PostAddSelectedMemberToPartner_URL: 'v1/Member/PostAddSelectedMemberToPartner',
     DeleteSelectedPartnersFromMember_URL: 'v1/Member/DeletePartnersMembers',
     GetMembersForDropdown_URL: 'v1/Member/GetMembersForDropdown',
     DeleteSelectedMembersFromPartner_URL: 'v1/Member/DeletePartnersMembers',
     GetAdminAccountInformation_URL: 'v1/Member/GetAdminAccountInformation',
     PutUpdateAdminUserData_URL: 'v1/Member/PutUpdateAdminUserData',

     GetMembersBy_URL: 'v1/Member/GetMembersBy',
     PostAddTransporterPlates_URL: 'v1/Member/PostAddTransporterPlates',
     PostAddTradePlates_URL: 'v1/Member/PostAddTradePlates',
     PutUpdateTransporterPlates_URL: 'v1/Member/PutUpdateTransporterPlate',
     PutUpdateTradePlates_URL: 'v1/Member/PutUpdateTradePlate',
     DeleteTransporterPlate_URL: 'v1/Member/DeleteTransporterPlate',
     DeleteTradePlate_URL: 'v1/Member/DeleteTradePlate',
     
     MemberDocument: 'v1/Files/GetMembersDocument',
     GetSupplierInsuranceRenewalDates_URL: 'v1/Member/GetSupplierInsuranceRenewalDates',

     /** Groups */
     GetGroups_URL: 'v1/Groups/GetGroups',
     GetGroupsForDropdown_URL: 'v1/Groups/GetGroupsForDropdown',
     PutAddGroupFavourite_URL: 'v1/Groups/PutAddGroupFavourite',
     DeleteGroupFavourite_URL: 'v1/Groups/DeleteGroupFavourite',
     PutAddGroupBlock_URL: 'v1/Groups/PutAddGroupBlock',
     DeleteGroupBlock_URL: 'v1/Groups/DeleteGroupBlock',
     PostAddGroup_URL: 'v1/Groups/PostAddGroup',
     GetGroup_URL: 'v1/Groups/GetGroup',
     PutUpdateGroup_URL: 'v1/Groups/PutUpdateGroup',
     AddOrUpdateGroupSavedDetails_URL: 'v1/Groups/PostAddOrUpdateGroupSavedDetails',
     DeleteGroupSavedDetails_URL: 'v1/Groups/DeleteGroupSavedDetails',

     /** Options */
     GetCustomerSetForDropdown_URL: 'v1/Options/GetCustomerSetForDropdown',
     GetLanguages_URL: 'v1/Options/GetLanguages',

     /** Feedback */
     GetFeedbackList_URL: 'v1/Member/GetFeedbackListAdmin',
     GetFeedback_URL: 'v1/Member/GetFeedback',
     PostAddFeedback_URL: 'v1/Member/PostAddFeedbackAdmin',
     PutUpdateFeedback_URL: 'v1/Member/PutUpdateFeedbackAdmin',
     DeleteFeedback_URL: 'v1/Member/DeleteFeedbackAdmin',

     /** Partners */
     GetPartner_URL: 'v1/Partner/GetPartner',
     GetPartners_URL: 'v1/Partner/GetPartners',
     PostAddPartner_URL: 'v1/Partner/PostAddPartner',
     PutUpdatePartner_URL: 'v1/Partner/PutUpdatePartner',
     DeletePartner_URL: 'v1/Partner/DeletePartner',
     PostSelectedMembersToPartner_URL: 'v1/Partner/PostAddMemberToPartner',

     /** Saved Search */
     GetSavedSearches_URL: 'v1/SavedSearches/PostGetSavedSearches',
     GetSavedSearch_URL: 'v1/SavedSearches/GetSavedSearch',
     PostAddSavedSearch_URL: 'v1/SavedSearches/PostAddSavedSearch',
     PutUpdateSavedSearch_URL: 'v1/SavedSearches/PutUpdateSavedSearch',
     DeleteSavedSearch_URL: 'v1/SavedSearches/DeleteSavedSearch',

     // Inspection Report
     GetInspectionReportDownloadPOC_URL: 'v1/InspectionReport/DownloadPOC',
     GetInspectionReportDownloadPOD_URL: 'v1/InspectionReport/DownloadPOD',
     GetInspectionReportDownloadArchivedPOC_URL: 'v1/InspectionReport/DownloadArchivedPOC',
     GetInspectionReportDownloadArchivedPOD_URL: 'v1/InspectionReport/DownloadArchivedPOD',

     /** Jobs */
     DeleteQuote_URL: 'v1/Quote/PutMarkQuoteDeleted',
     GetAdminUsersForDropdown_URL: 'v1/Jobs/GetAdminUsersForDropdown',
     GetCanRelistJob_URL: 'v1/Jobs/GetCanRelistJob',
     GetJob_URL: 'v1/Jobs/GetJob',
     GetJobs_URL: 'v1/Jobs/PostGetJobsAdmin',
     GetMarginForQuote_URL: 'v1/Jobs/GetMarginForQuote',
     GetPostAdminAbortMotabilityJob_URL: 'v1/Jobs/PostAdminAbortMotabilityJob',
     GetPreviousSupplyingMembersByRange_URL: 'v1/Member/GetPreviousSupplyingMembersByRange',
     GetQuoteFeeDetails_URL: 'v1/Quote/GetQuoteFeeDetails',
     GetReleaseNotes_URL: 'v1/Jobs/GetReleaseNotes',
     GetSupplyingMembersNearby_URL: 'v1/Member/GetSupplyingMembersNearby',
     PostAddQuote_URL: 'v1/Quote/PostAddQuote',
     PutAcceptQuote_URL: 'v1/Quote/PutAcceptQuote',
     PutRelistJob_URL: 'v1/Jobs/PutRelistJob',
     PutReOfferJob_URL: 'v1/Jobs/PutReOfferJob',
     PutReviveJob_URL: 'v1/Jobs/PutReviveJob',
     PutUnassignSupplyingMember_URL: 'v1/Jobs/PutUnassignSupplyingMember',
     PutUnlockJob_URL: 'v1/Jobs/AdminUnlockJob',
     PutUpdateAdditionalNotes_URL: 'v1/Jobs/PutUpdateAdditionalNotes',
     PutUpdateDeliveryContact_URL: 'v1/Jobs/PutUpdateDeliveryContact',
     PutUpdateJobAdminNotes_URL: 'v1/Jobs/PutUpdateJobAdminNotes',
     PutUpdateJobAdminUser_URL: 'v1/Jobs/PutUpdateJobAdminUser',
     PutUpdateJobFixedPrices_URL: 'v1/Jobs/PutUpdateJobFixedPrices',
     PutUpdateJobGuaranteedPrices_URL: 'v1/Jobs/PutUpdateJobGuaranteedPrices',
     PutUpdateJobReference_URL: 'v1/Jobs/PutUpdateJobReference',
     PutUpdateJobRequestMoreQuotes_URL: 'v1/Jobs/PutUpdateJobRequestMoreQuotes',
     PutUpdateJobStatus_URL: 'v1/Jobs/PutUpdateJobStatus',
     PutUpdateQuoteMargin_URL: 'v1/Quote/PutUpdateQuoteMargin',
     UploadNewProof_URL: 'v1/Jobs/PutUploadNewProof',

     /** Admin */
     GetAdminRolesForDropdown_URL: 'v1/Admin/GetAdminRolesForDropdown',
     PostAddAdministrator_URL: 'v1/Admin/PostAddAdministrator',
     PutUpdateAdministrator_URL: 'v1/Admin/PutUpdateAdministrator',
     DeleteAdministrator_URL: 'v1/Admin/DeleteAdministrator',
     GetAdministrators_URL: 'v1/Admin/GetAdministrators',
     GetAdministrator_URL: 'v1/Admin/GetAdministrator',
     PutUpdateAdminPassword_URL: 'v1/Admin/PutUpdateAdminPassword',

     /** Admin Role */
     GetAdminRoles_URL: 'v1/Admin/PostFetchAdminRoles',
     GetAdminRole_URL: 'v1/Admin/GetAdminRole',
     PostAddAdminRole_URL: 'v1/Admin/PostAddAdminRole',
     PutUpdateAdminRole_URL: 'v1/Admin/PutUpdateAdminRole',
     DeleteAdminRole_URL: 'v1/Admin/DeleteAdminRole',
     PostAddSelectedAdminRoleToAdminUser_URL: 'v1/Admin/PostAddSelectedAdminRoleToAdminUser',
     DeleteSelectedAdminRolesFromAdminUser_URL: 'v1/Admin/DeleteSelectedAdminRolesFromAdminUser',
     PostAddAdminRolePermission_URL: 'v1/Admin/PostAddAdminRolePermission',
     PutUpdateAdminRolePermission_URL: 'v1/Admin/PutUpdateAdminRolePermission',
     DeleteAdminRolePermission_URL: 'v1/Admin/DeleteAdminRolePermission',
     GetAdminPermissions_URL: 'v1/Admin/GetAdminPermissions',

     /** Dealer Margin */
     GetRequestingMemberFeeFixedPrices_URL: 'v1/Member/GetRequestingMemberFeeFixedPrices',
     GetRequestingMemberFeeQuotePrices_URL: 'v1/Member/GetRequestingMemberFeeQuotePrices',
     PostAddOrUpdateRequestingMemberFeeFixedRanges_URL: 'v1/Member/AddOrUpdateRequestingMemberFeeFixedRanges',
     PostAddOrUpdateRequestingMemberFeeQuoteRanges_URL: 'v1/Member/AddOrUpdateRequestingMemberFeeQuoteRanges',
     DeleteRequestingMemberFixedRanges_URL: 'v1/Member/DeleteRequestingMemberFixedRanges',
     DeleteRequestingMemberQuoteRanges_URL: 'v1/Member/DeleteRequestingMemberQuoteRanges',

     /** Invoices*/
     GetInvoice_URL: 'v1/Invoices/GetInvoice', 
     GetInvoices_URL: 'v1/Invoices/PostGetInvoices',
     PostAddInvoice_URL: 'v1/Invoices/PostAddInvoice',
     PutUpdateInvoice_URL: 'v1/Invoices/PutUpdateInvoice',
     PutEmailInvoice_URL: 'v1/Invoices/PutEmailInvoice',
     DeleteInvoice_URL: 'v1/Invoices/DeleteInvoice',
     PutRegenerateInvoice_URL: 'v1/Invoices/PutRegenerateInvoice',
     ViewInvoicePDF_URL: 'v1/Invoices/ViewInvoicePDF',//TODO: implement endpoint (missing on API side) --> Admin website, see View Invoices acttions
     DownloadCodaFile_URL: 'v1/Invoices/DownloadCodaFile',//TODO: implement endpoint (missing on API side) --> Admin website, see View Invoices actions
     GetInvoiceFile_URL: 'v1/Files/GetInvoice',
     GetCodaFile_URL: 'v1/Files/GetCodaFile',

     /** Jobs Activity */
     GetJobsActivityHistoryList_URL: 'v1/JobsActivityHistory/GetJobsActivityHistoryList',
     ExportJobActivityHistoryToCSV_URL: 'v1/JobsActivityHistory/ExportJobActivityHistoryToCSV',

     /** Payments  */
     GetPayments_URL: 'v1/Payments/PostGetPayments',
     PutMakePayment_URL: 'v1/Payments/PutMakePaymentNow',
     ViewUserInvoice_URL: 'v1/Payments/ViewUserInvoice',//TODO: implement endpoint (missing on API side) --> Admin website, see View Payments actions (when job is Paused)

     /** Prices */
     GetPriceList_URL: 'v1/Price/PostGetPrices',
     DeletePrice_URL: 'v1/Price/DeletePrice', 

     /** Price Group */
     DeletePriceGroup_URL: 'v1/PriceGroup/DeletePriceGroup',
     GetPriceGroupList_URL: 'v1/PriceGroup/GetPriceGroupListAdmin',
     GetPriceGroupSources_URL: 'v1/PriceGroup/GetPriceGroupSources',
     GetPriceGroupTargets_URL: 'v1/PriceGroup/GetPriceGroupTargets',
     GetPriceGroupPrices_URL: 'v1/PriceGroup/GetPriceGroupPrices',
     GetPriceGroup_URL: 'v1/PriceGroup/GetPriceGroup',
     PostAddPriceGroup_URL: 'v1/PriceGroup/PostAddPriceGroup',
     GetPriceGroupSourcesAndTargetsByName_URL: 'v1/PriceGroup/GetPriceGroupSourcesAndTargetsByName',
     PostAddSourceToPriceGroup_URL: 'v1/PriceGroup/PostAddSourceToPriceGroup',
     GetPricesByTypeAndName_URL: 'v1/PriceGroup/GetPricesByTypeAndName',
     GetPricePriceGroups_URL: 'v1/PriceGroup/GetPricePriceGroups',
     PostAddPrice_URL: 'v1/Price/PostAddPrice',
     PostAddPriceToPriceGroup_URL: 'v1/PriceGroup/PostAddPriceToPriceGroup',
     PutUpdatePrice_URL: 'v1/Price/PutUpdatePrice',
     DeletePriceFromPriceGroup_URL: 'v1/PriceGroup/DeletePriceFromPriceGroup',
     DeleteTargetFromPriceGroup_URL: 'v1/PriceGroup/DeleteTargetFromPriceGroup',
     DeleteSourceFromPriceGroup_URL: 'v1/PriceGroup/DeleteSourceFromPriceGroup',
     PostAddTargetToPriceGroup_URL: 'v1/PriceGroup/PostAddTargetToPriceGroup',
     PutUpdatePriceGroup_URL: 'v1/PriceGroup/PutUpdatePriceGroup',

     /** Payments */
     PutUpdatePaymentAdminNotes_URL: 'v1/Payments/PutUpdatePaymentAdminNotes',
     UpdateProofStatus_URL: 'v1/Jobs/PutUpdateProofStatus',
     PutPausePayment_URL: 'v1/Payments/PutPausePayment',
     PutMakePaymentNow_URL: 'v1/Payments/PutMakePaymentNow',
     PutMarkPaymentAsNotPayable_URL: 'v1/Payments/PutMarkPaymentAsNotPayable',
     PutUpdatePaymentDueDate_URL: 'v1/Payments/PutUpdatePaymentDueDate',
     PutCheckPayment_URL: 'v1/Payments/PutCheckPayment',
     PutUncheckPayment_URL: 'v1/Payments/PutUncheckPayment',
     PutRefundPrePayment_URL: 'v1/Payments/PutRefundPrePayment',

     /*Permissions */
     GetAdministrationInformation_URL: 'v1/Admin/GetAccountInformation',

     /*Tasks */
     PostUploadMemberCodaReferencesFile_URL: 'v1/Member/PostUploadUpdateMemberCodaReferencesFile',
     PostSendSmartDebitFile_URL: 'v1/Bills/PostSendSmartDebitFile',
     PostUploadMarkRequestingMemberInvoicesAsPaidFile_URL: 'v1/Invoices/PostUploadMarkRequestingMemberInvoicesAsPaidFile',
     PostAutoRemoveOldExpiredJobs_URL: 'v1/Admin/PostAutoRemoveOldExpiredJobs',
     PostPerformDailyPaymentRun_URL: 'v1/Admin/PostPerformDailyPaymentRun',
     PostPerformInvoiceRunForDeferredFees_URL: 'v1/Admin/PostPerformInvoiceRunForDeferredFees',
     PerformFortnightlyInvoiceRun_URL: 'v1/Admin/PerformFortnightlyInvoiceRun',
     PostPerformWeeklyInvoiceRun_URL: 'v1/Admin/PostPerformWeeklyInvoiceRun',
     PostGenerateDailyStatsReport_URL: 'v1/Admin/PostGenerateDailyStatsReport',
     PostGeneratePrePaymentFile_URL: 'v1/Admin/PostGeneratePrePaymentFile',
     PostAutoInsuranceReminders_URL: 'v1/Admin/PostAutoInsuranceReminders',
     PostSendEmailsToInactiveMembers_URL: 'v1/Admin/PostSendEmailsToInactiveMembers',
     PostAutoReRequestQuotes_URL: 'v1/Admin/PostAutoReRequestQuotes',

     /** File Upload*/
     PostUploadFile_URL: 'v1/Public/PostUploadFiles',
     GetStorageServiceSasToken_URL: 'v1/Public/GetStorageServiceSasToken',

     /** Billing */
     GetBill_URL:'v1/Bills/GetBill',
     GetBills_URL: 'v1/Bills/PostGetBilling',
     GetInvoiceDatesForDropdown_URL: 'v1/Bills/GetInvoiceDatesForDropdown',
     PutUpdateBill_URL: 'v1/Bills/PutUpdateBill',
     PutMarkPaymentsAndMembershipAsPaid_URL: 'v1/Bills/PutMarkPaymentsAndMembershipAsPaid',

     /** Vehicles*/
     GetVehicle_URL:'v1/Vehicle/GetVehicle',
     GetVehicles_URL: 'v1/Vehicle/GetVehiclesAdmin',
     PostAddVehicle_URL: 'v1/Vehicle/PostAddVehicle',
     PutUpdateVehicle_URL: 'v1/Vehicle/PutUpdateVehicle',
     DeleteVehicle_URL: 'v1/Vehicle/DeleteVehicle',

     /** Reporting */
     GetReportsJobsByUser_URL: 'v1/Jobs/PostGetReportJobsByUser',
     GetReportsNewOrUpdatedMembers_URL:"v1/Member/PostGetReportsNewOrUpdatedMembers",
     GetReportStatistics_URL: 'v1/Jobs/PostGetStatistics',
     GetReportJobsByUsersToCSV_URL: 'v1/Jobs/GetReportJobsByUsersToCSV',
     GetReportsNewOrUpdatedMembersToCSV_URL: 'v1/Member/GetReportsNewOrUpdatedMembersToCSV',
     
     /** GetUIPermissions */
     GetAdminUIPermissions_URL: 'v1/Admin/GetAdminUIPermissions',

}
