import { AddMemberModel } from "../../../../Models/Members/AddMember/AddMemberModel";
export interface AddMemberForm {
    formData: AddMemberModel;
    formError: AddMemberErrors
}

export type AddMemberErrors = {
    MovexSetLimit: string,
    DelphiScore: string,
    RecommendedLimit: string,
    UserName: string,
    ConfirmPassword: string,
}

export const MemberKeys = {
    /*  Fee Overrides & Deferrment */
    FeePerVehicleOverride: "FeePerVehicleOverride",
    QuotedFeeMinimumOverride: "QuotedFeeMinimumOverride",
    FixedFeeMinimumOverride: "FixedFeeMinimumOverride",
    QuotedFeesOverride: "QuotedFeesOverride",
    FixedFeesOverride: "FixedFeesOverride",
    FeesDeferred: "FeesDeferred",

    /*  Basic Member Fields */
    ID: "ID",
    UserName: "UserName",
    Email: "Email",
    EmailVerificationDate: "EmailVerificationDate",
    Password: "Password",
    RePassword: "RePassword",
    ConfirmPassword: "ConfirmPassword",
    LastLoginDate: "Date",
    LastModified: "Date",
    Active: "Active",
    Disabled: "Disabled",
    Disabled_Reason: "Disabled_Reason",
    SignupDate: "Date",
    Notes: "Notes",
    MemberType: "MemberType",
    IsKeyAccount: "IsKeyAccount",
    CanSeeKeyAccounts: "CanSeeKeyAccounts",
    HearAboutMovex: "HearAboutMovex",
    HearAboutMovex_Other: "HearAboutMovex_Other",
    LanguageId: "LanguageId",
    ComplianceSubmitted: "ComplianceSubmitted",
    ExpectationsSubmitted: "ExpectationsSubmitted",
    SignedBy: "SignedBy",
    PositionHeld: "PositionHeld",
    SupplierComplianceDate: "SupplierComplianceDate",
    SupplierExpectationsDate: "SupplierExpectationsDate",
    OnlyAcceptMovexPocPods: "OnlyAcceptMovexPocPods",
    NiNumber: "NiNumber",
    UniqueTaxpayerRef: "UniqueTaxpayerRef",
    
    /* MoDel Fields */
    IsModelSupplier: "IsModelSupplier",
    ModelAccountId: "ModelAccountId",
    
    /* Coda Fields */
    CodaCustomerReference: "CodaCustomerReference",
    CodaSupplierReference: "CodaSupplierReference",

    /* Main Contact */
    FirstName: "FirstName",
    Surname: "Surname",
    DateOfBirth: "DateOfBirth",
    Landline: "Landline",
    Mobile: "Mobile",
    MainContactPhotoID: "MainContactPhotoID",

    /* Company */
    Company: "Company",
    Company_TradingStatus: "Company_TradingStatus",
    Company_RegistrationNumber: "Company_RegistrationNumber",
    Company_Description: "Company_Description",
    Website: "Website",

    /* Business Address */
    Address1: "Address1",
    Address2: "Address2",
    Address3: "Address3",
    Town: "Town",
    County: "County",
    PostCode: "PostCode",
    PostCodeLookup: "PostCodeLookup",
    Country: "Country",
    Latitude: "Latitude",
    Longitude: "Longitude",
    ProofOfBusinessAddress: "ProofOfBusinessAddress",

    /* Registered Address */
    IsRegisteredAddressDifferent: "IsRegisteredAddressDifferent",
    RegisteredAddress1: "RegisteredAddress1",
    RegisteredAddress2: "RegisteredAddress2",
    RegisteredAddress3: "RegisteredAddress3",
    RegisteredTown: "RegisteredTown",
    RegisteredCounty: "RegisteredCounty",
    RegisteredPostCode: "RegisteredPostCode",
    RegisteredPostCodeLookup: "RegisteredPostCodeLookup",
    RegisteredCountry: "RegisteredCountry",

    /* Parter Contact */
    IsThereAPartner: "IsThereAPartner",
    Partner_ContactName: "Partner_ContactName",
    Partner_Landline: "Partner_Landline",
    Partner_Mobile: "Partner_Mobile",
    Partner_Email: "Partner_Email",
    Partner_ProofOfAddress: "Partner_ProofOfAddress",

    /* Dealer Only Options */
    DrivenMaxCostPerVehiclePerMile: "DrivenMaxCostPerVehiclePerMile",
    TransportedMaxCostPerVehiclePerMile: "TransportedMaxCostPerVehiclePerMile",
    DrivenGuaranteedPriceMinimumRate: "DrivenGuaranteedPriceMinimumRate",
    DrivenGuaranteedPriceMaximumRate: "DrivenGuaranteedPriceMaximumRate",
    TransportedGuaranteedPriceMinimumRate: "TransportedGuaranteedPriceMinimumRate",
    TransportedGuaranteedPriceMaximumRate: "TransportedGuaranteedPriceMaximumRate",
    MaskContactInfoForJobDetails: "",
    AvgVehiclesMoved: "AvgVehiclesMoved",
    TypeOfBusiness: "TypeOfBusiness",
    TypeOfBusiness_Other: "TypeOfBusiness_Other",
    RequireTermsAndConditionsAcceptance: "RequireTermsAndConditionsAcceptance",
    TermsAndConditions: "TermsAndConditions",

    /* Transporter Only Options */
    Company_YearEstablished: "Company_YearEstablished",
    ServiceType: "ServiceType",
    NoOfDrivers: "NoOfDrivers",
    AgeOfSingle: "AgeOfSingle",
    Hgv: "Hgv",
    DriversHaveIDs: "DriversHaveIDs",
    DriversHaveUniforms: "DriversHaveUniforms",
    NoOfTransporters: "NoOfTransporters",
    ClassicService: "ClassicService",
    PrestigeService: "PrestigeService",
    EnclosedTransport: "EnclosedTransport",
    CanTransport_Cars: "CanTransport_Cars",
    CanTransport_Vans: "CanTransport_Vans",
    CanTransport_Hgvs: "CanTransport_Hgvs",
    CanTransport_MotorCycles: "CanTransport_MotorCycles",
    CanTransport_Special: "CanTransport_Special",
    NoOfLowLoaders: "NoOfLowLoaders",
    NoOf1CarTransporters: "NoOf1CarTransporters",
    NoOf2To3CarTransporters: "NoOf2To3CarTransporters",
    NoOf5To7CarTransporters: "NoOf5To7CarTransporters",
    NoOf8To9CarTransporters: "NoOf8To9CarTransporters",
    NoOf10PlusCarTransporters: "NoOf10PlusCarTransporters",
    CoverageArea_UK: "CoverageArea_UK",
    CoverageArea_Ireland: "CoverageArea_Ireland",
    CoverageArea_Europe: "CoverageArea_Europe",
    DriverIMEI: "DriverIMEI",
    TransportersOverThreeAndAHalf: "TransportersOverThreeAndAHalf",
    ServiceTypeTransported: "ServiceTypeTransported",
    ServiceTypeDriven: "ServiceTypeDriven",
    ServiceLicenseDocuments: "ServiceLicenseDocuments",

    /* Insurance */
    DrivenInsuranceCoverValue: "DrivenInsuranceCoverValue",
    DrivenInsuranceRenewalDate: "DrivenInsuranceRenewalDate",
    TransportedSingleVehicleInsuranceCoverValue: "TransportedSingleVehicleInsuranceCoverValue",
    TransportedSingleVehicleInsuranceRenewalDate: "TransportedSingleVehicleInsuranceRenewalDate",
    TransportedLoadLimitInsuranceCoverValue: "TransportedLoadLimitInsuranceCoverValue",
    TransportedLoadLimitInsuranceRenewalDate: "TransportedLoadLimitInsuranceRenewalDate",
    TransporterInsuranceDocuments: "TransporterDocuments",

    /* Email Preferences - Dealer / Subcontractor */
    Email_RequestQuote: "Email_RequestQuote",
    Email_NewOfferReceived: "Email_NewOfferReceived",
    Email_OfferUpdated: "Email_OfferUpdated",
    Email_SupplierConfirmsJob: "Email_SupplierConfirmsJob",
    Email_SupplierCompletesProofOfCollection: "Email_SupplierCompletesProofOfCollection",
    Email_SupplierCompletesProofOfDelivery: "Email_SupplierCompletesProofOfDelivery",
    Email_SupplierCompletesJob: "Email_SupplierCompletesJob",
    Email_InvoiceForJobSent: "Email_InvoiceForJobSent",

    /* Email Preferences - Transporter */
    Email_PlaceQuote: "Email_PlaceQuote",
    Email_AcceptQuote: "Email_AcceptQuote",
    Email_RejectQuote: "Email_RejectQuote",
    Email_InvoiceToSupplier: "Email_InvoiceToSupplier",

    /* Credit Report */
    MovexSetLimit: "MovexSetLimit",
    DelphiScore: "DelphiScore",
    Risk: "Risk",
    PaymentType: "PaymentType",
    RecommendedLimit: "RecommendedLimit",
    DateOfCreditReport: "DateOfCreditReport",
    UsedCredit: 'UsedCredit',
    RemainingCredit: 'RemainingCredit',
    CreditReportFile: "CreditReportFile",

    /* Payment Detail */
    InvoiceFrequency: "InvoiceFrequency",
    InvoiceMaxAmount: "InvoiceMaxAmount",
    InvoiceMaxLineItems: "InvoiceMaxLineItems",
    PaymentMethod: "PaymentMethod",
    PaySuccSelected: "PaySuccSelected",
    PayStartDate: "PayStartDate",
    PaymentReference: "PaymentReference",
    VATRegistered: "VatRegistered",
    VATNo: "VatNo",
    IsBankAdded: "IsBankAdded",
    BankName: "BankName",
    AccountName: "AccountName",
    AccountNo: "AccountNo",
    SortCode: "SortCode",
    SortCode1: "SortCode1",
    SortCode2: "SortCode2",
    SortCode3: "SortCode3",

    /*  Membership Fees */
    MonthlyFeeType: "MonthlyFeeType",

    /* Messages User Sees But Can Hide */
    Message_HowToGetPaid_Hide: "Message_HowToGetPaid_Hide",
    Message_FixedPrice_Hide: "Message_FixedPrice_Hide",

    /* Sub Member Options */
    ParentID: "ParentID",
    CustomerSetID: "CustomerSetID",
    IsDriverUser: "IsDriverUser",

    ModifiedByAdminUserId: "ModifiedByAdminUserId",
    ModifiedByMemberId: "ModifiedByMemberId",

    /* Trade Plates */
    TradePlates: "TradePlates",
    TransporterPlates: "TransporterPlates",
}

