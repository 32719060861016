import React from 'react';
import { useParams } from 'react-router-dom';
import { renderHeader } from '../../../../Components/PageHeader';
import { membersActionCreators } from '../../../../Store/Members/ViewMembers/Action';
import ScrollToNavbar from '../EditMember/ScrollToNavbar';
import GeneralForm from '../EditMember/Tabstrip/General';
import { MemberIDParam } from '../EditMember/Tabstrip/MemberBlocks/Types';
import './index.css';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useAppDispatch } from '../../../../Store/hooks';
import { ApplicationState } from '../../../../Store/Types';

const AddMember: React.FC<AddMemberType> = (): JSX.Element => {
  const { memberID } = useParams<MemberIDParam>();
  const dispatch: ThunkDispatch<ApplicationState, null, AnyAction> = useAppDispatch()

  dispatch(membersActionCreators.showScrollbar(true));

  return (
    <div>
      {renderHeader(memberID ? 'Edit Member' : 'Add Member')}
      <div className="container-fluid add-member-outer-container">
        <div style={{ display: 'flex' }}>
          <ScrollToNavbar />
          <div className="col-md-8">
            <GeneralForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMember;

type AddMemberType = {};
