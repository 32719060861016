import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { DeleteQuoteModel } from '../../../../../Models/Jobs/EditJob';
import { EntityCreatedOrUpdatedViewModel, MovexErrorResponse } from '../../../../../Types';

export const useDeleteQuote = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, MovexErrorResponse, DeleteQuoteModel> => {
  const deleteQuote = (payload: DeleteQuoteModel) => {
    return agent.jobs.deleteQuote(payload);
  };

  return useMutation(deleteQuote);
};
