import { OptionType } from "../../../../Components/FormControls";
import { JobActivityType } from "../../../../Utils/Enums";

export const JobActivityTypeOptions: OptionType[] = [
    {
        value: JobActivityType.JobCreated, label: "Job Created"
    }, {
        value: JobActivityType.JobUpdated, label: "Job Updated"
    }, {
        value: JobActivityType.JobMarkedAsNotForMe, label: "Job Marked As Not For Me"
    }, {
        value: JobActivityType.JobDeleted, label: "Job Deleted"
    }, {
        value: JobActivityType.JobCancelled, label: "Job Cancelled"
    }, {
        value: JobActivityType.JobRevived, label: "Job Revived"
    }, {
        value: JobActivityType.JobCompleted, label: "Job Completed"
    }, {
        value: JobActivityType.QuotesRequested, label: "Quotes Requested"
    }, {
        value: JobActivityType.FixedPriceJobActive, label: "Fixed Price Job Active"
    }, {
        value: JobActivityType.QuoteCreated, label: "Quote Created"
    }, {
        value: JobActivityType.QuoteUpdated, label: "Quote Updated"
    }, {
        value: JobActivityType.QuoteAccepted, label: "Quote Accepted"
    }, {
        value: JobActivityType.QuoteMarkedAsDeleted, label: "Quote Marked As Deleted"
    }, {
        value: JobActivityType.QuoteDeleted, label: "Quote Deleted"
    }, {
        value: JobActivityType.QuotedJobActive, label: "Quoted Job Active"
    }, {
        value: JobActivityType.QuoteJobDeclined, label: "Quoted Job Declined"
    }, {
        value: JobActivityType.JobAborted, label: "Job Aborted"
    }, {
        value: JobActivityType.QuoteMarginUpdated, label: "Quote Margin Updated"
    }, {
        value: JobActivityType.QuotesAutomaticallyCreated, label: "Quotes Automatically Created"
    }, {
        value: JobActivityType.QuoteAcceptedAndJobActive, label: "Quote Accepted & Job Active"
    }, {
        value: JobActivityType.JobsVehicleAborted, label: "Jobs Vehicle Aborted"
    }, {
        value: JobActivityType.JobLocked, label: "Job Locked"
    }, {
        value: JobActivityType.JobUnlocked, label: "Job Unlocked"
    }, {
        value: JobActivityType.JobReOffered, label: "Job ReOffered"
    }, {
        value: JobActivityType.AdminRemovedFailed, label: "Admin Removed/Failed"
    }, {
        value: JobActivityType.DraftJob, label: "Draft Job"
    }, {
        value: JobActivityType.JobBooked, label: "Job Booked"
    }, {
        value: JobActivityType.JobExpired, label: "Job Expired"
    }, {
        value: JobActivityType.JobRelisted, label: "Job Relisted"
    }, {
        value: JobActivityType.SupplierUnassigned, label: "Supplier Unassigned"
    }, {
        value: JobActivityType.JobStatusUnknown, label: "Unknown Job Status"
    },
]
