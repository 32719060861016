import { useCallback, useEffect, useState } from "react";

export default function useCountdown(decreaseIntervalInMs: number): [number, (startCount: number) => void] {
    const [countLeft, setCountLeft] = useState(0);

    useEffect(() => {
        if (countLeft < 0) return;

        const timeout = setTimeout(() => {
            setCountLeft(countLeft - decreaseIntervalInMs);
        }, decreaseIntervalInMs);

        return () => clearTimeout(timeout);
    }, [decreaseIntervalInMs, countLeft]);

    const start = useCallback((startCount: number) => {
        setCountLeft(startCount);
    }, []);

    return [countLeft, start];
}