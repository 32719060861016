import { FormikProvider } from "formik";
import { Col, Row } from "react-bootstrap";
import { FormControlKeys } from "../../../../../../Components/FormControls";
import { FormContainer } from "../../../../../../Components/Formik";
import FormikControl from "../../../../../../Components/Formik/FormikControl";

interface ModalFooterProps {
    formik: any;
    onClose: () => void;
    onSubmit: () => void;
}

export default function ModalFooter({ formik, onClose, onSubmit }: ModalFooterProps) {
    return <FormikProvider value={formik}>
        <FormContainer onSubmit={(e) => { e.preventDefault(); }}>
            <Row>
                <Col>
                    <FormikControl
                        label="Close"
                        control={FormControlKeys.Button}
                        size="sm"
                        onClick={onClose}
                    />
                </Col>
                <Col>
                    <FormikControl
                        label="Submit"
                        control={FormControlKeys.Button}
                        onClick={onSubmit}
                        size="sm"
                    />
                </Col>
            </Row>
        </FormContainer>
    </FormikProvider>
}