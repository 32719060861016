import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormControlKeys } from "../../../../../Components/FormControls";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import { DistanceFromCollectionOrDelivery } from "../../../Members/Constants";
import {
  FieldSetColSpan10Section,
  FieldSetColSpan5AlignedSection,
  FieldSetHeader,
  FlexContainer,
  HoverableLink,
} from "../../../../../Components/Formik";
import {
  DivInputWidth,
  SearchBox,
  LabelItem,
} from "../../../../../Components/UI";
import {
  JobViewModel,
  MemberSearchFilter,
  MemberForJobSection,
} from "../../../../../Models/Jobs/EditJob";
import { useGetSupplyingMembersNearby } from "../Hooks";
import { SupplyingmembersNearby } from "../Types";
import { RouteConstants } from "../../../../../Routes";
import { Alert, useAlert } from "../../../../../Components";
import { SearchRadius } from "../../../../../Utils/Enums";

const Section5SupplyingMembersNearby: React.FC<GeneralFormProps> = (
  props
): JSX.Element => {
  const { job } = props;

  /** useStates */
  const [formikInitialValues, setFormikInitialValues] =
    useState<JobViewModel>(job);

  const [showSupplyingMembersList, setShowSupplyingMembersList] =
    useState<boolean>(false);

  const [isWaitingData, setIsWaitingData] = useState<boolean>(false);

  const [filter, setFilter] = useState<MemberSearchFilter>(
    {} as MemberSearchFilter
  );

  /** Custom Hooks */
  const { alert, setAlert } = useAlert();

  /** React Query Hook */
  const {
    isSuccess,
    data,
    refetch: getSupplyingMembersNearby,
    error: getSupplyingMembersNearbyError,
  } = useGetSupplyingMembersNearby(filter);

  /** Methods */
  const formik = useFormik({
    initialValues: {
      ...formikInitialValues,
      WithinMiles: SearchRadius.C_25_Miles,
      PreviouslyCompletedRoute: false
    },
    onSubmit: () => {
      fetchMembers();
    },
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  const fetchMembers = () => {
    setIsWaitingData(true);
    const payload: MemberSearchFilter = {
      JobId: job.Id,
      WithinMiles: values.WithinMiles,
      PreviouslyCompletedRoute: values.PreviouslyCompletedRoute,
    };
    setFilter(payload);
  };

  /** useEffect */
  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      getSupplyingMembersNearby();
      setIsWaitingData(false);
      setShowSupplyingMembersList(true);
    }
  }, [filter]);

  useEffect(() => {
    setFormikInitialValues(job);
  }, [job]);

  useEffect(() => {
    if (getSupplyingMembersNearbyError) {
      setAlert({
        ...alert,
        show: true,
        header: getSupplyingMembersNearbyError?.Subject,
        body: getSupplyingMembersNearbyError?.Description,
      });
    }
  }, [getSupplyingMembersNearbyError]);
  

  const getLabel = () => {
    if (isWaitingData)
      return (
        <span>
          <i className="fa fa-spinner fa-spin"></i> Waiting...
        </span>
      );
    return "Find Supplier Nearby";
  };

  return (
    <>
      <FormikProvider value={formik}>
      <Alert {...alert} />
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <FieldSetHeader>Supplying Members Nearby</FieldSetHeader>
            <div>
              <SearchBox className="search-box-colors">
                <FlexContainer>
                  <FieldSetColSpan10Section>
                    <LabelItem>Distance:</LabelItem>
                    <DivInputWidth>
                      <FormikControl
                        control={FormControlKeys.Select}
                        name={SupplyingmembersNearby.WithinMiles}
                        options={DistanceFromCollectionOrDelivery}
                        value={values.WithinMiles}
                      />
                    </DivInputWidth>
                  </FieldSetColSpan10Section>
                  <FieldSetColSpan5AlignedSection>
                    <FormikControl
                      control={FormControlKeys.Button}
                      label={getLabel()}
                      type="submit"
                      disabled={isWaitingData}
                    />
                  </FieldSetColSpan5AlignedSection>
                </FlexContainer>
              </SearchBox>
            </div>

            {(showSupplyingMembersList || isWaitingData) && (
              <table id='id_table_supplying_members_nearby' className="table table-bordered table-condensed table-striped">
                <thead>
                  <tr>
                    <td>UserName</td>
                    <td>Company</td>
                    <td>Contact</td>
                    <td>PostCode</td>
                    <td>Landline</td>
                    <td>Mobile</td>
                    <td>ServiceType</td>
                    <td>DistanceMiles</td>
                  </tr>
                </thead>
                <tbody>
                  {isWaitingData && <tr>Loading...</tr>}
                  {!isWaitingData &&
                    data?.Suppliers &&
                    data?.Suppliers.map(
                      (data: MemberForJobSection, index: number) => {
                        const {
                          Id,
                          UserName,
                          Company,
                          Contact_Name1,
                          PostCode,
                          Landline,
                          Mobile,
                          ServiceType,
                          DistanceInMiles,
                          NoOfDrivers,
                          NoOfTransporters,
                        } = data;
                        return (
                          <tr>
                            <td>
                              <HoverableLink
                                to={`${RouteConstants.EditMember}/${Id}`}
                              >
                                {UserName}
                              </HoverableLink>
                            </td>
                            <td>{Company}</td>
                            <td>{Contact_Name1}</td>
                            <td>{PostCode}</td>
                            <td>{Landline}</td>
                            <td>{Mobile}</td>
                            <td>
                              Driven {" (" + NoOfDrivers + ")"}
                              <br />
                              Transported {" (" + NoOfTransporters + ")"}
                            </td>
                            <td>
                              {DistanceInMiles &&
                                Math.ceil(DistanceInMiles) +
                                  " from Delivery"}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  {!isWaitingData && data?.Suppliers.length === 0 && "No data"}
                </tbody>
              </table>
            )}
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  job: JobViewModel;
};

export default Section5SupplyingMembersNearby;
