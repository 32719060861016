import AccessTime from '@mui/icons-material/AccessTime';
import { Tooltip } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { date, number, object, string } from 'yup';
import { DrivenServiceIcon, TransportedServiceIcon } from '../../../../../Assets/Images';
import { FormControlKeys, OptionType } from '../../../../../Components/FormControls';
import { FieldSetHeader, FlexContainer, HoverableLink } from '../../../../../Components/Formik';
import FormikControl from '../../../../../Components/Formik/FormikControl';
import { SpanBoldItem } from '../../../../../Components/UI';
import { AddQuoteModel, JobViewModel } from '../../../../../Models/Jobs/EditJob';
import { TimeUnit, adjustToUTC, reFormatUkDate, replaceLineBreaksWithBR } from '../../../../../Utils';
import { JobType, MemberType, QuoteType, ServiceType } from '../../../../../Utils/Enums';
import { useGetMembersForDropdown } from '../../../Members/EditMember/Tabstrip/MemberBlocks/Hooks';
import { usePostAddQuote } from '../Hooks';
import { usePutGetMarginForQuote } from '../Hooks/usePutGetMarginForQuote';
import { PlaceQuoteFormKey, PlaceQuoteOrAcceptFixedPrices } from '../Types';
import { estimateFuel } from './CalcFunctions';
import { useQuoteFeeDetailDetailManagement } from './CustomHook/useQuoteFeeDetailDetailManagement';
import { Alert, Loading, ShowPleaseWait, useAlert } from '../../../../../Components';
import * as Yup from 'yup';
import { GetSupplierInsuranceRenewalDatesResponse } from '../../../../../Models/Jobs/EditJob/GetSupplierInsuranceRenewalDatesResponse';
import { useGetSupplierInsuranceRenewalDates } from '../Hooks/useGetSupplierInsuranceRenewalDates';

const SectionPlaceQuoteOrAcceptFixedPrices: React.FC<GeneralFormProps> = (props): JSX.Element => {
     const { job, showAlert, refetchJob } = props;
     const { alert, setAlert, handleAlertClose } = useAlert();
     const dateFrom = new Date(job.CollectionDate ? job.CollectionDate : new Date().getTime());
     const dateTo = new Date(job.DeliveryDate);

     /** useState - Driven Section */
     const [drivenSupplierFee, setDrivenSupplierFee] = useState<number>(0);
     const [drivenSupplierAmount, setDrivenSupplierAmount] = useState<number>(0);
     const [drivenMargin, setDrivenMargin] = useState<number>(0);
     const [drivenSupplierInsuranceExpiry, setDrivenSupplierInsuranceExpiry] = useState<GetSupplierInsuranceRenewalDatesResponse | undefined>();
     /** useState - Transported Section */
     const [transportedMargin, setTransportedMargin] = useState<number>(0);
     const [transportedSupplierFee, setTransportedSupplierFee] = useState<number>(0);
     const [transportedSupplierAmount, setTransportedSupplierAmount] = useState<number>(0);
     const [transportedSupplierInsuranceExpiry, setTransportedSupplierInsuranceExpiry] = useState<GetSupplierInsuranceRenewalDatesResponse | undefined>();
     /** useState - Common Job States */
     const [fuelEstimation, setFuelEstimation] = useState<number>(0);
     const [showFixedPrice, setShowFixedPrice] = useState<boolean>(false);
     const [showVATAdditional, setShowVATAdditional] = useState<boolean>(false);
     const [isGuaranteedPrice, setIsGuaranteedPrice] = useState<boolean>(false);
     const [isFuelRequired, setIsFuelRequired] = useState<boolean>(false);
     const [isFuelIncluded, setIsFuelIncluded] = useState<boolean>(false);
     /** useState - Handling Form States */
     const [isNewQuoteFixedPriceTransported, setIsNewQuoteFixedPriceTransported] = useState<boolean>(false);
     const [isNewQuoteFixedPriceDriven, setIsNewQuoteFixedPriceDriven] = useState<boolean>(false);
     const [isDrivenValidationSchema, setIsDrivenValidationSchema] = useState<boolean>(true);
     const [jobRetrieved, setJobRetrieved] = useState<boolean>(false);

     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
     const [isLoading, setIsLoading] = useState<boolean>(false);

     /** React Query Hooks */
     const { data: memberList = [], error: errorGettingMembersForDropDown } = useGetMembersForDropdown(MemberType.TransportCompany);
     const {
          mutate: postAddQuote,
          isLoading: isPostAddQuoteLoading,
          data: addPostAddQuoteResponse,
          error: addPostQuoteError
     } = usePostAddQuote();

     const { mutate: putGetMarginForQuote, data: putGetMarginForQuoteResponse, error: errorPutGetMarginForQuote } = usePutGetMarginForQuote();
     const { mutate: putGetTransportedMarginForQuote, data: putGetTransportedMarginForQuoteResponse, error: errorPutTransportedGetMarginForQuote } = usePutGetMarginForQuote();
     const { mutate: getSupplierInsuranceRenewalDates, data: getSupplierInsuranceRenewalDatesResponse } = useGetSupplierInsuranceRenewalDates();
     
     /** Custom Hook for Fees Management */
     const {
          setQuoteDrivenAmountEnteredByUser,
          setQuoteTransportedAmountEnteredByUser,
          getQuoteFeeDetailsResponse,
          getTransportedQuoteFeeDetailsResponse,
          getDrivenQuoteFeeDetails,
          getTransportedQuoteFeeDetails,
     } = useQuoteFeeDetailDetailManagement(job);

     /** useEffects */
     /** Initialize hidden elements */
     useEffect(() => {
          const fetchFuelEstimation = async () => {
               const estimation  = await estimateFuel(job.NoOfVehicles, job.DistanceMiles);
               setFuelEstimation(estimation);
           };
          if (job.JobType === JobType.GuaranteedPrice) setIsGuaranteedPrice(true);
          if (job.JobType && [JobType.FixedPrice, JobType.Both].includes(job.JobType)) setShowFixedPrice(true);
          setIsFuelIncluded(job.Driven_FuelRequired !== null);
          setIsFuelRequired(job.Driven_FuelRequired === true);
          setJobRetrieved(true);
          fetchFuelEstimation();
          setFieldValue(PlaceQuoteOrAcceptFixedPrices.QuoteDrivenAmount, job.DFixedPriceMin);
          setFieldValue(PlaceQuoteOrAcceptFixedPrices.QuoteTransportedAmount, job.TFixedPriceMin);
     }, [job]);

     /**  Calculate Margin and Fees for quotes */
     useEffect(() => {
          /** DRIVEN */
          putGetMarginForQuote({
               jobID: job.Id,
               quoteAmount: job.DFixedPriceMin,
               quoteType: QuoteType.Driven,
          });
          getDrivenQuoteFeeDetails();

          /** TRANSPORTED */
          putGetTransportedMarginForQuote({
               jobID: job.Id,
               quoteAmount: job.TFixedPriceMin,
               quoteType: QuoteType.Transported,
          });
          getTransportedQuoteFeeDetails();
     }, [jobRetrieved]);

     useEffect(() => {
          if (getSupplierInsuranceRenewalDatesResponse) {
               if (getSupplierInsuranceRenewalDatesResponse.Id == values.QuoteDrivenSupplier) {
                    setDrivenSupplierInsuranceExpiry(getSupplierInsuranceRenewalDatesResponse);
               }
               if (getSupplierInsuranceRenewalDatesResponse.Id == values.QuoteTransportedSupplier) {
                    setTransportedSupplierInsuranceExpiry(getSupplierInsuranceRenewalDatesResponse);
               }
          }
     }, [getSupplierInsuranceRenewalDatesResponse]);

     useEffect(() => {
          if (getQuoteFeeDetailsResponse) {
               setDrivenSupplierFee(getQuoteFeeDetailsResponse.SupplierFee);
               setDrivenSupplierAmount(getQuoteFeeDetailsResponse.SupplierAmount);
          }
          if (putGetMarginForQuoteResponse) {
               setDrivenMargin(putGetMarginForQuoteResponse);
          }

          if (getTransportedQuoteFeeDetailsResponse) {
               setTransportedSupplierFee(getTransportedQuoteFeeDetailsResponse.SupplierFee);
               setTransportedSupplierAmount(getTransportedQuoteFeeDetailsResponse.SupplierAmount);
          }
          if (putGetTransportedMarginForQuoteResponse) {
               setTransportedMargin(putGetTransportedMarginForQuoteResponse);
          }
     }, [
          getQuoteFeeDetailsResponse,
          putGetMarginForQuoteResponse,
          getTransportedQuoteFeeDetailsResponse,
          putGetTransportedMarginForQuoteResponse,
     ]);

     useEffect(() => {
          if (addPostAddQuoteResponse) {
               if (addPostAddQuoteResponse?.Error) {
                    setAlert({
                         ...alert,
                         show: true,
                         header: addPostAddQuoteResponse?.Error.Subject,
                         body: addPostAddQuoteResponse?.Error.Description,
                    });
               }
               else if (addPostAddQuoteResponse?.Id == 0) {
                    showAlert("Error", 'An unknown error occurred while adding the quote.');
               } 

               if (addPostAddQuoteResponse?.Id && addPostAddQuoteResponse?.Id != 0) {
                    setAlert({
                         ...alert,
                         show: true,
                         header: "Quote Added",
                         body: 'Success! A new quote has been added.',
                         closeCallback: () => {
                              resetForm();
                              handleAlertClose();
                              refetchJob();
                         }
                    });
               }
          }
     }, [addPostAddQuoteResponse]);

     useEffect(() => {
          if(errorPutGetMarginForQuote) {
               showAlert(errorPutGetMarginForQuote.Subject, errorPutGetMarginForQuote.Description);
          }
     }, [errorPutGetMarginForQuote]);

     useEffect(() => {
          if(errorPutTransportedGetMarginForQuote) {
               showAlert(errorPutTransportedGetMarginForQuote.Subject, errorPutTransportedGetMarginForQuote.Description);
          }
     }, [errorPutTransportedGetMarginForQuote]);

     useEffect(() => {
          if(errorGettingMembersForDropDown) {
               showAlert(errorGettingMembersForDropDown.Subject, errorGettingMembersForDropDown.Description);
          }
     }, [errorGettingMembersForDropDown]);

     useEffect(() => {
          setIsLoading(isPostAddQuoteLoading);
     }, [isPostAddQuoteLoading]);

     /** Functions */
     const formik = useFormik({
          initialValues: initialValues,
          validationSchema: isDrivenValidationSchema ? validationSchemaForDrivenForm : validationSchemaForTransportedForm,
          onSubmit: () => {},
          enableReinitialize: true,
     });
     const { values, resetForm, setFieldValue } = formik;

     /** this function will trigger the Fees and MarginQuote calculation endpoints */
     const calcDrivenCustomerMarginAndSupplierFee = (updatedAmount?: number) => {
          if (updatedAmount === undefined || updatedAmount === null || updatedAmount <= 0) return;
          setQuoteDrivenAmountEnteredByUser(updatedAmount);
          putGetMarginForQuote({
               jobID: job.Id,
               quoteAmount: updatedAmount,
               quoteType: QuoteType.Driven,
          });
     };

     /** this function will trigger the Fees and MarginQuote calculation endpoints */
     const calcTransportedCustomerMarginAndSupplierFee = (updatedAmount?: number) => {
          if (updatedAmount === undefined || updatedAmount === null || updatedAmount <= 0) return;
          setQuoteTransportedAmountEnteredByUser(updatedAmount);
          putGetTransportedMarginForQuote({
               jobID: job.Id,
               quoteAmount: updatedAmount,
               quoteType: QuoteType.Transported,
          });
     };

     const handlePutUpdateSubmitDrivenQuoteOrAcceptFixedPrice = () => {
          formik.validateForm();
          if (job.RequestingMemberHasTerms && values.DrivenTermsAndConditionsAccepted != true) {
               showAlert('Requesting Member Has Terms', 'Please confirm that you have read and accept the terms and conditions.');
               return;
          }

          if (job.AdditionalNotes && values.DrivenDealerNotesAccepted != true) {
               showAlert('Job Has Additional Notes', 'Please confirm that you have read and accept the notes.');
               return;
          }
          
          if (values.DrivenCollectionDate && values.DrivenDeliveryDate) {
               let payload: AddQuoteModel = {
                    JobID: job.Id,
                    IsFixedPrice: job.JobType === JobType.FixedPrice || JobType.Both ? true : false,
                    SupplierID: Number(values.QuoteDrivenSupplier),
                    QuoteType: QuoteType.Driven,
                    DrivenAmount: Number(values.QuoteDrivenAmount),
                    DrivenFuelIncluded: isFuelIncluded,
                    CollectionDate: adjustToUTC(new Date(values.DrivenCollectionDate)),
                    DeliveryDate: adjustToUTC(new Date(values.DrivenDeliveryDate)),
                    ExpirationValue: Number(values.DrivenExpirationValue),
                    ExpirationPeriod: Number(values.DrivenExpirationPeriod),
                    TermsAndConditionsAccepted: values.DrivenTermsAndConditionsAccepted,
                    AdditionalNotes: values.DrivenAdditionalNotes,
               };
               postAddQuote(payload);
          }
     };

     const handleSubmitAddTransportedQuoteOrAcceptFixedPrice = () => {
          formik.validateForm();
          if (job.RequestingMemberHasTerms && values.TransportedTermsAndConditionsAccepted != true) {
               showAlert('Requesting Member Has Terms', 'Please confirm that you have read and accept the terms and conditions.');
               return;
          }

          if (job.AdditionalNotes && values.TransportedDealerNotesAccepted != true) {
               showAlert('Job Has Additional Notes', 'Please confirm that you have read and accept the notes.');
               return;
          }

          if (values.TransportedCollectionDate && values.TransportedDeliveryDate) {
               let payload: AddQuoteModel = {
                    JobID: job.Id,
                    IsFixedPrice: job.JobType === JobType.FixedPrice || JobType.Both ? true : false,
                    SupplierID: Number(values.QuoteTransportedSupplier),
                    QuoteType: QuoteType.Transported,
                    TransportedAmount: Number(values.QuoteTransportedAmount),
                    CollectionDate: adjustToUTC(new Date(values.TransportedCollectionDate)),
                    DeliveryDate: adjustToUTC(new Date(values.TransportedDeliveryDate)),
                    ExpirationValue: Number(values.TransportedExpirationValue),
                    ExpirationPeriod: Number(values.TransportedExpirationPeriod),
                    TermsAndConditionsAccepted: values.TransportedTermsAndConditionsAccepted,
                    AdditionalNotes: values.TransportedAdditionalNotes
               };
               postAddQuote(payload);
          }
     };

     const ShowNotes = () => {
          if (job.AdditionalNotes) {
               showAlert('Notes', replaceLineBreaksWithBR(job.AdditionalNotes))
          }
     }

     const ShowTermsAndConditions = () => {
          if (job.RequestingMemberHasTerms) {
               showAlert('Terms and Conditions', replaceLineBreaksWithBR(job.RequestingMemberTerms))
          }
     }

     const CheckShouldShowAcceptFixedPrice = () => {
          let isFixedPrice = job.JobType === JobType.FixedPrice || job.JobType === JobType.Both ? true : false;

          if (job.ServiceType === ServiceType.Driven || job.ServiceType === ServiceType.Both) {
               if ((new Date(values.DrivenCollectionDate) >= dateFrom && new Date(values.DrivenCollectionDate) <= dateTo) 
               && (new Date(values.DrivenDeliveryDate) <= dateTo && new Date(values.DrivenDeliveryDate) >= dateFrom)) {
                    if ((isFixedPrice === true || isGuaranteedPrice === true) && (job.DFixedPriceMin == values.QuoteDrivenAmount)) {
                         setIsNewQuoteFixedPriceDriven(true);
                    } else {
                         setIsNewQuoteFixedPriceDriven(false);
                    }
               }
               else {
                    setIsNewQuoteFixedPriceDriven(false);
               }
          }

          if (job.ServiceType === ServiceType.Transported || job.ServiceType === ServiceType.Both) {
               if ((new Date(values.TransportedCollectionDate) >= dateFrom && new Date(values.TransportedCollectionDate) <= dateTo)
               && (new Date(values.TransportedDeliveryDate) <= dateTo && new Date(values.TransportedDeliveryDate) >= dateFrom)) {
                    if ((isFixedPrice === true || isGuaranteedPrice === true) && (job.TFixedPriceMin == values.QuoteTransportedAmount)) {
                         setIsNewQuoteFixedPriceTransported(true);
                    } else {
                         setIsNewQuoteFixedPriceTransported(false);
                    }
               }
               else {
                    setIsNewQuoteFixedPriceTransported(false);
               }
          }
     };

     useEffect(() => {
          if (values.QuoteDrivenSupplier) {
              getSupplierInsuranceRenewalDates(values.QuoteDrivenSupplier);
          } else {
              setDrivenSupplierInsuranceExpiry(undefined);
          }
     }, [values.QuoteDrivenSupplier]);

     useEffect(() => {
          if (values.QuoteTransportedSupplier) {
              getSupplierInsuranceRenewalDates(values.QuoteTransportedSupplier);
          } else {
              setTransportedSupplierInsuranceExpiry(undefined);
          }
     }, [values.QuoteTransportedSupplier]);

     useEffect(() => {
          CheckShouldShowAcceptFixedPrice();
     }, [
          values.DrivenCollectionDate,
          values.DrivenDeliveryDate,
          values.TransportedCollectionDate,
          values.TransportedDeliveryDate,
          values.QuoteDrivenAmount,
          values.QuoteTransportedAmount
     ])

     const HoverableDealerNotes = (
          <div>
            Please confirm you have read the dealer {' '}
            <HoverableLink
              to={''}
              onClick={(event) => {
                event.preventDefault();
                ShowNotes();
              }}>
              <SpanBoldItem>notes</SpanBoldItem>
            </HoverableLink>
          </div>
        );
        
     
     const HoverableTermsAndConditions = (
          <div>
               Please confirm acceptance of the dealer {' '}
               <HoverableLink 
                    to={''}
                    onClick={(event) => {
                         event.preventDefault();
                         ShowTermsAndConditions();
                       }}>
                    <SpanBoldItem>terms and conditions</SpanBoldItem>
               </HoverableLink>
          </div>
     );

     return (
          <>
               <FormikProvider value={formik}>
               <Alert {...alert} />
               <Loading loading={isLoading} />
               <ShowPleaseWait show={showPleaseWait} />
                    <Form onSubmit={formik.handleSubmit}>
                         <fieldset>
                              <br />
                              <FieldSetHeader>Place Quote or Accept Fixed Price</FieldSetHeader>
                              <table id='id_table_place_quote_or_accept_fixed_price' className='table'>
                                   <thead>
                                        <tr>
                                             <td>Supplier</td>
                                             <StyledTd>Type</StyledTd>
                                             <td>Amount</td>
                                             <td>Margin</td>
                                             <td>Fee</td>
                                             <td>They Get</td>
                                             <td>Collection</td>
                                             <td>Delivery</td>
                                             <StyledTd200>Expires</StyledTd200>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {/* DRIVEN FORM */}
                                        {(job.ServiceType === ServiceType.Driven || job.ServiceType === ServiceType.Both) && (
                                             <TrStyledTableRow>
                                                  <td>
                                                       <FormikControl
                                                            control={FormControlKeys.Select}
                                                            name={PlaceQuoteFormKey.QuoteDrivenSupplier}
                                                            placeholder='Select'
                                                            options={getMemberListOptions(memberList)}
                                                            value={values.QuoteDrivenSupplier}
                                                       />
                                                       {drivenSupplierInsuranceExpiry && (
                                                            <div className='text-danger'>
                                                                 {(() => {
                                                                      if (!drivenSupplierInsuranceExpiry.DrivenInsuranceRenewalDate) {
                                                                           return <div>The supplier's cannot quote for driven jobs as they do not have driven insurance</div>
                                                                      }

                                                                      if (values.DrivenDeliveryDate && new Date(drivenSupplierInsuranceExpiry.DrivenInsuranceRenewalDate!) < new Date(values.DrivenDeliveryDate)) {
                                                                           return <div>The supplier's driven insurance ends on {reFormatUkDate(drivenSupplierInsuranceExpiry.DrivenInsuranceRenewalDate!.toString())} which is before the selected delivery date</div>;
                                                                      }
                                                                 })()}
                                                            </div>
                                                       )}
                                                  </td>
                                                  <td>
                                                       <Tooltip title='Driven'>
                                                            <img alt='Driven' src={DrivenServiceIcon} />
                                                       </Tooltip>
                                                  </td>
                                                  <td>
                                                       {/*****DRIVEN - AMOUNT *****/}
                                                       <FormikControl
                                                            style={{ maxWidth: '100px' }}
                                                            name={PlaceQuoteOrAcceptFixedPrices.QuoteDrivenAmount}
                                                            control={FormControlKeys.InputGroup}
                                                            text='£'
                                                            onChange={(e: any) => {
                                                                 formik.setFieldValue(
                                                                      PlaceQuoteOrAcceptFixedPrices.QuoteDrivenAmount,
                                                                      e.target.value
                                                                 );
                                                            }}
                                                            onBlur={(e: any) => {
                                                                 if (Number(e.target.value) < job.DFixedPriceMin) {
                                                                      formik.setFieldValue(
                                                                           PlaceQuoteOrAcceptFixedPrices.QuoteDrivenAmount,
                                                                           job.DFixedPriceMin);
                                                                      e.target.value = job.DFixedPriceMin;
                                                                 }
                                                                 calcDrivenCustomerMarginAndSupplierFee(e.target.value);
                                                            }}
                                                            min={0}
                                                       />
                                                       {showVATAdditional && (
                                                            <>
                                                                 <div className='pull-right'>+VAT</div>
                                                                 <br />
                                                            </>
                                                       )}

                                                       {isGuaranteedPrice && (
                                                            <label>{'Guaranteed : £' + job.DGuaranteedPrice}</label>
                                                       )}
                                                       {(showFixedPrice || isGuaranteedPrice) && (
                                                            <GreenLabel>{'Min : £' + job.DFixedPriceMin_Original}</GreenLabel>
                                                       )}
                                                       <br />
                                                       {(showFixedPrice || isGuaranteedPrice) && (
                                                            <RedLabel>{'Max: £' + job.DFixedPriceMax_Original}</RedLabel>
                                                       )}
                                                  </td>
                                                  <td>{'£' + Number(drivenMargin).toFixed(2)}</td>
                                                  <td>{'£' + Number(drivenSupplierFee).toFixed(2)}</td>
                                                  <td>
                                                       <span>{'£' + Number(drivenSupplierAmount).toFixed(2)}</span>
                                                  </td>
                                                  <td>
                                                       <FormikControl
                                                            control={FormControlKeys.DatePicker}
                                                            name={PlaceQuoteFormKey.DrivenCollectionDate}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                       />
                                                  </td>
                                                  <td>
                                                       <FormikControl
                                                            control={FormControlKeys.DatePicker}
                                                            name={PlaceQuoteFormKey.DrivenDeliveryDate}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                       />
                                                  </td>
                                                  <td>
                                                       <FlexContainer>
                                                            <FormikControl
                                                                 name={PlaceQuoteFormKey.DrivenExpirationValue}
                                                                 control={FormControlKeys.InputGroup}
                                                                 text={<AccessTime />}
                                                                 value={
                                                                      values.DrivenExpirationValue === 0 ? '' : values.DrivenExpirationValue
                                                                 }
                                                            />
                                                            <FormikControl
                                                                 style={{ minWidth: '90px' }}
                                                                 control={FormControlKeys.Select}
                                                                 name={PlaceQuoteFormKey.DrivenExpirationPeriod}
                                                                 placeholder='Select'
                                                                 options={TimeUnit}
                                                                 value={values.DrivenExpirationPeriod}
                                                            />
                                                       </FlexContainer>
                                                  </td>
                                             </TrStyledTableRow>
                                        )}

                                        {(job.ServiceType === ServiceType.Driven || job.ServiceType === ServiceType.Both) && (
                                             <BgColorTr>
                                                  <td>
                                                       <FormikControl
                                                            style={{}}
                                                            name={PlaceQuoteOrAcceptFixedPrices.DrivenAdditionalNotes}
                                                            control={FormControlKeys.Textarea}
                                                            showLabel={false}
                                                            placeholder='Additional Notes...'
                                                       />
                                                  </td>
                                                  <td></td>
                                                  <td>
                                                       {/******DRIVEN - FUEL INCLUDED OR NOT *******/}
                                                       {isFuelIncluded && (
                                                            <>
                                                                 {isFuelRequired && (
                                                                      <>
                                                                           <FormikControl
                                                                                name={PlaceQuoteFormKey.DrivenFuelIncluded}
                                                                                control={FormControlKeys.Radio}
                                                                                label='Incl Fuel'
                                                                                radioId={`radioAccountEnabled`}
                                                                                defaultChecked={true}
                                                                           />

                                                                           <BlueLabel>
                                                                                {'£' +
                                                                                     Number(fuelEstimation).toFixed(2) +
                                                                                     ' Estimated Fuel'}
                                                                           </BlueLabel>
                                                                      </>
                                                                 )}
                                                                 {!isFuelRequired && (
                                                                      <>
                                                                           <label> No Fuel Required</label>
                                                                      </>
                                                                 )}
                                                            </>
                                                       )}

                                                       {!isFuelIncluded && (
                                                            <>
                                                                 <FormikControl
                                                                      name={PlaceQuoteFormKey.DrivenFuelIncluded}
                                                                      control={FormControlKeys.Radio}
                                                                      label='+ Fuel'
                                                                      defaultChecked={false}
                                                                      radioId={`radioAccountEnabled`}
                                                                 />
                                                                 <FormikControl
                                                                      name={PlaceQuoteFormKey.DrivenFuelIncluded}
                                                                      control={FormControlKeys.Radio}
                                                                      label='Incl Fuel'
                                                                      defaultChecked={false}
                                                                      radioId={`radioAccountEnabled`}
                                                                 />
                                                            </>
                                                       )}
                                                  </td>
                                                  {/****** DRIVEN - CHECKBOXES *******/}
                                                  <RightAlignedTd colSpan={5}>
                                                       {job.AdditionalNotes && (
                                                            <FormikControl
                                                                 name={PlaceQuoteOrAcceptFixedPrices.DrivenDealerNotesAccepted}
                                                                 control={FormControlKeys.CheckboxReversed}
                                                                 label={HoverableDealerNotes}
                                                            />
                                                       )}

                                                       {job.RequestingMemberHasTerms && (
                                                            <FormikControl
                                                                 name={PlaceQuoteOrAcceptFixedPrices.DrivenTermsAndConditionsAccepted}
                                                                 control={FormControlKeys.CheckboxReversed}
                                                                 label={HoverableTermsAndConditions}
                                                            />
                                                       )}
                                                  </RightAlignedTd>
                                                  {/******* SUBMIT DRIVEN QUOTE *********/}
                                                  <td key={String(isNewQuoteFixedPriceDriven)}>
                                                  {isNewQuoteFixedPriceDriven && (
                                                       <CenteredDiv>
                                                            <FormikControl
                                                                 control={FormControlKeys.Button}
                                                                 label='Accept'
                                                                 name={PlaceQuoteOrAcceptFixedPrices.AcceptDrivenFixedPrices}
                                                                 type='submit'
                                                                 onClick={() => {
                                                                       setIsDrivenValidationSchema(true);
                                                                       handlePutUpdateSubmitDrivenQuoteOrAcceptFixedPrice();
                                                                 }}
                                                            />
                                                       </CenteredDiv>
                                                       )}
                                                       {!isNewQuoteFixedPriceDriven && (
                                                       <CenteredDiv>
                                                            <FormikControl
                                                                 control={FormControlKeys.Button}
                                                                 label='Submit'
                                                                 name={PlaceQuoteOrAcceptFixedPrices.QuoteDrivenAmount}
                                                                 type='submit'
                                                                 onClick={() => {
                                                                      setIsDrivenValidationSchema(true);
                                                                      handlePutUpdateSubmitDrivenQuoteOrAcceptFixedPrice();
                                                                 }}
                                                            />
                                                       </CenteredDiv>
                                                       )}
                                                  </td>
                                             </BgColorTr>
                                        )}
                                        {/* TRANSPORTED */}
                                        {(job.ServiceType === ServiceType.Transported ||
                                             job.ServiceType === ServiceType.Both) && (
                                             <tr className='no-bottom-border'>
                                                  <td>
                                                       <FormikControl
                                                            control={FormControlKeys.Select}
                                                            name={PlaceQuoteFormKey.QuoteTransportedSupplier}
                                                            placeholder='Select'
                                                            options={getMemberListOptions(memberList)}
                                                            value={values.QuoteTransportedSupplier}
                                                       />
                                                       {transportedSupplierInsuranceExpiry && (
                                                            <div className='text-danger'>
                                                                 {(() => {
                                                                      if (!transportedSupplierInsuranceExpiry.TransportedSingleVehicleInsuranceRenewalDate && !transportedSupplierInsuranceExpiry.TransportedLoadLimitInsuranceRenewalDate) {
                                                                           return <div>The supplier's cannot quote for transported jobs as they do not have transported insurance</div>
                                                                      }
                                                                      
                                                                      let singleVehicleRenewalDate = transportedSupplierInsuranceExpiry?.TransportedSingleVehicleInsuranceRenewalDate ?? new Date(0);
                                                                      let loadLimitRenewalDate = transportedSupplierInsuranceExpiry?.TransportedLoadLimitInsuranceRenewalDate ?? new Date(0);

                                                                      let transportedExpiryDate = singleVehicleRenewalDate > loadLimitRenewalDate ? singleVehicleRenewalDate : loadLimitRenewalDate;

                                                                      if (values.TransportedDeliveryDate && new Date(transportedExpiryDate!) < new Date(values.TransportedDeliveryDate)) {
                                                                           return <div>The supplier's transported insurance ends on {reFormatUkDate(transportedExpiryDate!.toString())} which is before the selected delivery date</div>;
                                                                      }
                                                                 })()}
                                                            </div>
                                                       )}
                                                  </td>
                                                  <td>
                                                       <Tooltip title='Transported'>
                                                            <img alt='Transported' src={TransportedServiceIcon} />
                                                       </Tooltip>
                                                  </td>
                                                  <td>
                                                       {/******* TRANSPORTED - AMOUNT *********/}
                                                       <FormikControl
                                                            style={{ maxWidth: '100px' }}
                                                            name={PlaceQuoteOrAcceptFixedPrices.QuoteTransportedAmount}
                                                            control={FormControlKeys.InputGroup}
                                                            text='£'
                                                            min={0}
                                                            onChange={(e: any) => {
                                                                 formik.setFieldValue(
                                                                      PlaceQuoteOrAcceptFixedPrices.QuoteTransportedAmount,
                                                                      e.target.value
                                                                 );
                                                            }}
                                                            onBlur={(e: any) => {
                                                                 if (Number(e.target.value) < job.TFixedPriceMin) {
                                                                      formik.setFieldValue(
                                                                           PlaceQuoteOrAcceptFixedPrices.QuoteTransportedAmount,
                                                                           job.TFixedPriceMin);
                                                                      e.target.value = job.TFixedPriceMin;
                                                                 }
                                                                 calcTransportedCustomerMarginAndSupplierFee(e.target.value);
                                                            }}
                                                       />
                                                       {showVATAdditional && <div className='pull-right'>+VAT</div>}
                                                       {isGuaranteedPrice && (
                                                            <label>
                                                                 {'Guaranteed: £ '}
                                                                 {job.TGuaranteedPrice ? job.TGuaranteedPrice : 0}
                                                            </label>
                                                       )}
                                                       {(showFixedPrice || isGuaranteedPrice) && (
                                                            <GreenLabel>
                                                                 {'Min: £'}
                                                                 {job.TFixedPriceMin_Original ? job.TFixedPriceMin_Original : 0}
                                                            </GreenLabel>
                                                       )}
                                                       <br />
                                                       {(showFixedPrice || isGuaranteedPrice) && (
                                                            <RedLabel>
                                                                 {'Max: £'}
                                                                 {job.TFixedPriceMax_Original ? job.TFixedPriceMax_Original : 0}
                                                            </RedLabel>
                                                       )}
                                                  </td>
                                                  <td>{'£' + Number(transportedMargin).toFixed(2)}</td>
                                                  <td>{'£' + Number(transportedSupplierFee).toFixed(2)}</td>
                                                  <td>
                                                       <span>{'£' + Number(transportedSupplierAmount).toFixed(2)}</span>
                                                  </td>
                                                  <td>
                                                       <FormikControl
                                                            control={FormControlKeys.DatePicker}
                                                            name={PlaceQuoteFormKey.TransportedCollectionDate}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                       />
                                                  </td>
                                                  <td>
                                                       <FormikControl
                                                            control={FormControlKeys.DatePicker}
                                                            name={PlaceQuoteFormKey.TransportedDeliveryDate}
                                                            dateFrom={dateFrom}
                                                            dateTo={dateTo}
                                                       />
                                                  </td>
                                                  <td>
                                                       <FlexContainer>
                                                            <FormikControl
                                                                 name={PlaceQuoteFormKey.TransportedExpirationValue}
                                                                 control={FormControlKeys.InputGroup}
                                                                 text={<AccessTime />}
                                                                 value={
                                                                      values.TransportedExpirationValue === 0
                                                                           ? ''
                                                                           : values.TransportedExpirationValue
                                                                 }
                                                            />
                                                            <FormikControl
                                                                 style={{ minWidth: '90px' }}
                                                                 control={FormControlKeys.Select}
                                                                 name={PlaceQuoteFormKey.TransportedExpirationPeriod}
                                                                 placeholder='Select'
                                                                 options={TimeUnit}
                                                                 value={values.TransportedExpirationPeriod}
                                                            />
                                                       </FlexContainer>
                                                  </td>
                                             </tr>
                                        )}

                                        {(job.ServiceType === ServiceType.Transported ||
                                             job.ServiceType === ServiceType.Both) && (
                                             <tr>
                                                  <td>
                                                       <FormikControl
                                                            style={{}}
                                                            name={PlaceQuoteFormKey.TransportedAdditionalNotes}
                                                            control={FormControlKeys.Textarea}
                                                            showLabel={false}
                                                            placeholder='Additional Notes...'
                                                       />
                                                  </td>
                                                  <td></td>
                                                  <td></td>
                                                  <RightAlignedTd colSpan={5}>
                                                       {' '}
                                                       {/******* TRANSPORTED - CHECKBOXES *********/}
                                                       {job.AdditionalNotes && (
                                                            <FormikControl
                                                                 name={PlaceQuoteFormKey.TransportedDealerNotesAccepted}
                                                                 control={FormControlKeys.CheckboxReversed}
                                                                 label={HoverableDealerNotes}
                                                            />
                                                       )}
                                                       {job.RequestingMemberHasTerms && (
                                                            <FormikControl
                                                                 name={PlaceQuoteFormKey.TransportedTermsAndConditionsAccepted}
                                                                 control={FormControlKeys.CheckboxReversed}
                                                                 label={HoverableTermsAndConditions}
                                                            />
                                                       )}
                                                  </RightAlignedTd>
                                                  {/******* TRANSPORTED - SUBMIT *********/}
                                                  <td key={String(isNewQuoteFixedPriceTransported)}>
                                                       {isNewQuoteFixedPriceTransported && (
                                                            <CenteredDiv>
                                                                 <FormikControl
                                                                      control={FormControlKeys.Button}
                                                                      label='Accept'
                                                                      name={PlaceQuoteOrAcceptFixedPrices.AcceptTransportedFixedPrices}
                                                                      type='submit'
                                                                      onClick={() => {
                                                                           setIsDrivenValidationSchema(true);
                                                                           handleSubmitAddTransportedQuoteOrAcceptFixedPrice();
                                                                      }}
                                                                 />
                                                            </CenteredDiv>
                                                       )}
                                                       {!isNewQuoteFixedPriceTransported && (
                                                            <CenteredDiv>
                                                                 <FormikControl
                                                                      control={FormControlKeys.Button}
                                                                      label='Submit'
                                                                      name={PlaceQuoteOrAcceptFixedPrices.QuoteTransportedAmount}
                                                                      type='submit'
                                                                      onClick={() => {
                                                                           setIsDrivenValidationSchema(false);
                                                                           handleSubmitAddTransportedQuoteOrAcceptFixedPrice();
                                                                      }}
                                                                 />
                                                            </CenteredDiv>
                                                       )}
                                                  </td>
                                             </tr>
                                        )}
                                   </tbody>
                              </table>
                         </fieldset>
                    </Form>
               </FormikProvider>
          </>
     );
};

const initialValues = {
     QuoteDrivenSupplier: 0,
     QuoteDrivenAmount: 0,
     DrivenCollectionDate: '',
     DrivenDeliveryDate: '',
     DrivenExpirationValue: 0,
     DrivenExpirationPeriod: undefined,
     DrivenDealerNotesAccepted: false,
     DrivenTermsAndConditionsAccepted: false,
     TransportedDealerNotesAccepted: false,
     QuoteTransportedSupplier: 0,
     QuoteTransportedAmount: 0,
     TransportedCollectionDate: '',
     TransportedDeliveryDate: '',
     TransportedExpirationValue: 0,
     TransportedExpirationPeriod: undefined,
     TransportedTermsAndConditionsAccepted: false,
     TransportedFuelIncluded: true,
     DrivenAdditionalNotes: '',
     TransportedAdditionalNotes: '',
};

const getMemberListOptions = (memberList: any[]): OptionType[] => {
     const options: OptionType[] = [];
     memberList.forEach((member) => {
          let className = '';
          if (member.HasAcceptedCurrentTsAndCs !== true) className = className + 'dropdown-tsandcs-invalid';
          if (member.PaySuccSelected !== true) className = className + 'dropdown-payment-not-setup';
          if (member.Active !== true) className = className + 'dropdown-inactive';
          if (member.Disabled === true) className = className + 'dropdown-disabled';
          options.push({
               value: member.Id,
               label: member.UserName,
               className: className,
          });
     });
     return options;
};

const todaysDate = new Date().toISOString().slice(0, 10);

const validationSchemaForDrivenForm = Yup.object({
     [PlaceQuoteOrAcceptFixedPrices.DrivenCollectionDate]:           
          date().nullable()
          .required('Collection date must be provided')
          .min(todaysDate, 'Collection date cannot be before today\'s date'),
     [PlaceQuoteOrAcceptFixedPrices.DrivenDeliveryDate]:           
          date().nullable()
          .required('Delivery date must be provided')
          .min(todaysDate, 'Delivery date cannot be before today\'s date'),
     [PlaceQuoteOrAcceptFixedPrices.QuoteDrivenSupplier]:           
          number().nullable()
          .notOneOf([0], 'You must select a supplier')
          .required(),
     [PlaceQuoteOrAcceptFixedPrices.QuoteDrivenAmount]:           
          number().nullable()
          .notOneOf([0], 'Amount must be provided')
          .typeError('Amount must be a number')
          .positive("Amount must be positive")
          .required('Amount must be provided'),
     [PlaceQuoteOrAcceptFixedPrices.DrivenExpirationValue]:          
          number().nullable()
          .typeError('Expiration value must be a number')
          .notOneOf([0], 'Expiration value must be provided')
          .positive("Expiration value must be positive")
          .required('Expiration value must be provided'),
     [PlaceQuoteOrAcceptFixedPrices.DrivenExpirationPeriod]:
          number().nullable()
          .typeError('Expiration value must be a number')
          .notOneOf([0], 'Expiration value must be provided')
          .positive("Expiration value must be positive")
          .required('Expiration value must be provided'),
});

const validationSchemaForTransportedForm = Yup.object({
     [PlaceQuoteOrAcceptFixedPrices.TransportedCollectionDate]: 
          date().nullable()
          .required('Collection date must be provided')
          .min(todaysDate, 'Collection date cannot be before today\'s date'),
     [PlaceQuoteOrAcceptFixedPrices.TransportedDeliveryDate]: 
          date().nullable()
          .required('Delivery date must be provided')
          .min(todaysDate, 'Delivery date cannot be before today\'s date'),
     [PlaceQuoteOrAcceptFixedPrices.TransportedExpirationPeriod]: 
          string().nullable()
          .notOneOf(['0', ''], 'Expiration period must be provided')
          .required('Expiration period must be provided'),
     [PlaceQuoteOrAcceptFixedPrices.TransportedExpirationValue]: 
          number().nullable()
          .typeError('Expiration value must be a number')
          .notOneOf([0], 'Expiration value must be provided')
          .positive("Expiration value must be positive")
          .required('Expiration value must be provided'),
     [PlaceQuoteOrAcceptFixedPrices.QuoteTransportedSupplier]: 
          number().nullable()
          .notOneOf([0], 'You must select a supplier')
          .required(),
     [PlaceQuoteOrAcceptFixedPrices.QuoteTransportedAmount]: 
          number().nullable()
          .notOneOf([0], 'Amount must be provided')
          .typeError('Amount must be a number')
          .positive("Amount must be positive")
          .required('Amount must be provided'),
});

type GeneralFormProps = {
     job: JobViewModel;
     showAlert: Function;
     refetchJob: Function;
};

export default SectionPlaceQuoteOrAcceptFixedPrices;

/** Styled components */

export const TrStyledTableRow = styled.tr`
     background-color: rgba(0, 0, 0, 0.05);
     border-style: none;
`;

const StyledTd = styled.td`
     min-width: 35px;
`;

const StyledTd200 = styled.td`
     min-width: 200px;
`;

const GreenLabel = styled.label`
     color: green;
`;

const RedLabel = styled.label`
     color: red;
`;

const CenteredDiv = styled.div`
     display: flex;
     align-items: center;
     justify-content: center;
`;

const RightAlignedTd = styled.td`
     text-align: right;
`;

const BlueLabel = styled.label`
     color: #3a87ad;
     font-size: 12px;
`;

const BgColorTr = styled.tr`
     background-color: rgba(0, 0, 0, 0.05);
`;
