import { Form, FormikProvider, useFormik } from "formik";
import { useEffect } from "react";
import { FormControlKeys } from "../../../../../../Components/FormControls";
import FormikControl from "../../../../../../Components/Formik/FormikControl";
import { SpanItem } from "../../../../../../Components/UI";
import { JobsVehicle } from "../../../../../../Models/Jobs/EditJob";

interface GeneralFormProps {
  vehicles: JobsVehicle[];
  func: Function
};

export default function VehiclesModule(props: GeneralFormProps) {
  const { func, vehicles } = props;

  /** Methods */
  const formik = useFormik({
    initialValues: { vehicles },
    onSubmit: () => { },
    enableReinitialize: true,
  });
  const { values } = formik;

  useEffect(() => {
    func(values);
  }, [values, func])

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <fieldset>
            <br />
            <SpanItem>Please ensure you have provided a VRM or a VIN for each vehicle.</SpanItem>
            <table id='id_table_job_vehicles_module' className="table table-bordered table-condensed table-striped">
              <thead>
                <tr>
                  <td>Make</td>
                  <td>Model</td>
                  <td>VRM</td>
                  <td>VIN</td>
                </tr>
              </thead>
              <tbody>
                {vehicles?.map((data: JobsVehicle, index: number) => {
                  const { VehicleModel, VehicleMake } = data;
                  return (
                    <tr key={index}>
                      <td>{VehicleMake}</td>
                      <td>{VehicleModel}</td>
                      <td>
                        <FormikControl
                          control={FormControlKeys.LabelledInput}
                          name={`vehicles[${index}].Vrm`}
                        />
                      </td>
                      <td>
                        <FormikControl
                          control={FormControlKeys.LabelledInput}
                          name={`vehicles[${index}].Vin`}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

