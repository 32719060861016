import { FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query";
import {
  Alert,
  ConfirmDialog,
  ModalDialog,
  useAlert
} from "../../../../../Components";
import {
  FieldSetHeader
} from "../../../../../Components/Formik";
import {
  AcceptQuoteModel,
  DeleteQuoteModel,
  JobResponseModel,
  JobsVehicle,
  JobViewModel,
  QuoteModel
} from "../../../../../Models/Jobs/EditJob";
import { AddJobVehicleModel } from "../../../../../Models/Jobs/EditJob/AddJobVehicleModel";
import { UpdateQuoteMarginRequest } from "../../../../../Models/Jobs/EditJob/UpdateQuoteMarginRequest";
import {
  ConfirmDialogContent
} from "../../../../../Utils";
import {
  JobStatus,
  JobType,
  MemberType,
  PaymentMethod,
  ServiceType
} from "../../../../../Utils/Enums";
import { useGetMembersForDropdown } from "../../../Members/EditMember/Tabstrip/MemberBlocks/Hooks";
import { useDeleteQuote } from "../Hooks";
import { usePutAcceptQuote } from "../Hooks/usePutAcceptQuote";
import { usePutUpdateQuoteMargin } from "../Hooks/usePutUpdateQuoteMargin";
import { PlacedQuoteJobDetails, placedQuoteJobDetailsInitialValues } from "./Models/PlacedQuoteJobDetails";
import ModalFooter from "./Modules/ModalFooter";
import PlacedQuoteRow from "./Modules/PlacedQuoteRow";
import VehiclesModule from "./Modules/VehiclesModule";

interface Props {
  isGetJobLoading: boolean;
  job: JobViewModel;
  quotes: QuoteModel[];
  vehicles: JobsVehicle[];
  showAlert: (header: string, body: string) => void;
  refetchJob: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<JobResponseModel, any>>;
};

export default function SectionPlacedQuotesAcceptedFixedPrices({ isGetJobLoading, job, quotes, vehicles, showAlert, refetchJob }: Props) {
  const { alert, setAlert, handleAlertClose } = useAlert();

  const [jobDetails, setJobDetails] = useState<PlacedQuoteJobDetails>(placedQuoteJobDetailsInitialValues);

  const [acceptedQuoteId, setAcceptedQuoteId] = useState(0);
  const [showAcceptQuoteModal, setShowAcceptQuoteModal] = useState(false);
  const [showQuoteHigherModal, setShowQuoteHigherModal] = useState(false);
  const [vehicleListForAcceptance, setVehicleListForAcceptance] = useState<any>({});

  const [deletedQuoteId, setDeletedQuoteId] = useState<number>(0);
  const [showQuoteDeletedModal, setShowQuoteDeletedModal] = useState<boolean>(false);

  const [updateQuoteMarginRequest, setUpdateQuoteMarginRequest] = useState<UpdateQuoteMarginRequest>();
  const [showUpdateQuoteMarginModal, setShowUpdateQuoteMarginModal] = useState<boolean>(false);

  const {
    mutate: deleteQuote,
    data: responseQuoteDeleted,
    isLoading: isLoadingQuoteDeleted,
    reset: resetQuoteDeleted,
  } = useDeleteQuote();

  const {
    data: memberList = [],
    error: errorMemberList,
    remove: resetMemberList,
  } = useGetMembersForDropdown(MemberType.TransportCompany);

  const {
    mutate: putAcceptQuote,
    data: responseAcceptQuote,
    isLoading: isLoadingAcceptQuote,
    reset: resetAcceptQuote,
  } = usePutAcceptQuote();

  const {
    mutate: putUpdateQuoteMargin,
    data: responseUpdateQuoteMargin,
    error: errorUpdateQuoteMargin,
    isLoading: isLoadingUpdateQuoteMargin,
    reset: resetUpdateMarginQuote,
  } = usePutUpdateQuoteMargin();

  const formik = useFormik({ initialValues: { DealerPays: -1 }, onSubmit: () => { }, enableReinitialize: true, });

  useEffect(() => {
    if (job) {
      const details: PlacedQuoteJobDetails = {
        isFuelInclusive: job.Driven_FuelRequired !== null ? true : false,
        isFuelRequired: job.Driven_FuelRequired === true,
        isJobBookedPrice: job.JobStatus === JobStatus.BookedJob,
        isJobCompletedPrice: job.JobStatus === JobStatus.CompletedJob,
        isJobFeeDeferred: job.FeesDeferred === true,
        isJobGuaranteedPrice: job.JobType === JobType.GuaranteedPrice,
        isJobPaymentPrePayMethod: job.PaymentMethod === PaymentMethod.PrePay,
        isJobPendingQuote: job.JobStatus === JobStatus.PendingQuotes,
        dFixedPriceMax: job.DFixedPriceMax,
        tFixedPriceMax: job.TFixedPriceMax,
      };

      setJobDetails(details);
    }
  }, [job]);

  useEffect(() => {
    if (responseAcceptQuote && !responseAcceptQuote.ErrorResponse && !alert.show) {
      setAlert({
        ...alert,
        show: true,
        header: "Quote Accepted",
        body: "Success! The quote has been accepted.",
        closeCallback: () => {
          resetAcceptQuote();
          refetchJob();
          setAlert({ ...alert, show: false });
        }
      });
    }
  }, [
    alert,
    responseAcceptQuote,
    handleAlertClose,
    refetchJob,
    resetAcceptQuote,
    setAlert
  ]);

  useEffect(() => {
    if (responseQuoteDeleted && !responseQuoteDeleted.Error && !alert.show) {
      setAlert({
        ...alert,
        show: true,
        header: "Quote Deleted",
        body: "Success! The quote has been deleted.",
        closeCallback: () => {
          resetQuoteDeleted();
          refetchJob();
          setAlert({ ...alert, show: false });
        }
      });
    }
  }, [
    alert,
    responseQuoteDeleted,
    handleAlertClose,
    refetchJob,
    resetQuoteDeleted,
    setAlert
  ]);

  useEffect(() => {
    if (responseUpdateQuoteMargin && !responseUpdateQuoteMargin.Error && !alert.show) {
      setAlert({
        ...alert,
        show: true,
        header: "Quote Margin Updated",
        body: "Success! The quote has been updated.",
        closeCallback: () => {
          resetUpdateMarginQuote();
          refetchJob();
          setAlert({ ...alert, show: false });
        }
      });
    }
  }, [
    alert,
    responseUpdateQuoteMargin,
    handleAlertClose,
    refetchJob,
    resetUpdateMarginQuote,
    setAlert
  ]);

  useEffect(() => {
    if (responseAcceptQuote?.ErrorResponse) {
      const { Description, Subject } = responseAcceptQuote.ErrorResponse;
      showAlert(Subject ?? '', Description ?? '');
      resetAcceptQuote();
    } else if (responseQuoteDeleted?.Error) {
      const { Description, Subject } = responseQuoteDeleted.Error;
      showAlert(Subject ?? '', Description ?? '');
      resetQuoteDeleted();
    } else if (errorMemberList) {
      showAlert(errorMemberList?.Subject ?? '', errorMemberList?.Description ?? '');
      resetMemberList();
    } else if (errorUpdateQuoteMargin) {
      const { Description, Subject } = errorUpdateQuoteMargin;
      showAlert(Subject ?? '', Description ?? '');
      resetUpdateMarginQuote();
    }
  }, [
    errorMemberList,
    errorUpdateQuoteMargin,
    responseAcceptQuote,
    responseQuoteDeleted,
    showAlert,
    resetAcceptQuote,
    resetMemberList,
    resetQuoteDeleted,
    resetUpdateMarginQuote
  ]);

  const onAcceptModalClose = useCallback(() => {
    setAcceptedQuoteId(0);
    setShowAcceptQuoteModal(false)
  }, []);

  const onAccept = useCallback((
    quoteId: number,
    additionalNotesRead: boolean | undefined,
    quoteType: ServiceType | undefined,
    quoteAmount: number | undefined
  ) => {
    if (additionalNotesRead !== undefined && additionalNotesRead === false) {
      showAlert('Quote Has Additional Notes', 'Please confirm that you have read and accept the notes.');
      return;
    }

    setAcceptedQuoteId(quoteId);

    if (!quoteType && !quoteAmount) {
      setShowAcceptQuoteModal(true);
    } else {
      const { dFixedPriceMax, tFixedPriceMax } = jobDetails;
      const maximumPrice = quoteType === ServiceType.Driven ? dFixedPriceMax : tFixedPriceMax;

      if (quoteAmount !== undefined && quoteAmount > maximumPrice) {
        setShowQuoteHigherModal(true);
      } else {
        setShowAcceptQuoteModal(true);
      }
    }
  }, [jobDetails, showAlert]);

  const onAcceptSubmit = useCallback(() => {
    onAcceptModalClose();

    const newVehiclesValues: AddJobVehicleModel[] = [];

    (vehicleListForAcceptance.vehicles).forEach((vehicle: any) => {
      let newVehicle: AddJobVehicleModel = {
        ID: vehicle.Id,
        JobID: vehicle.JobId,
        VRM: vehicle.Vrm,
        VIN: vehicle.Vin,
        VehicleType: vehicle.VehicleType,
        VehicleMake: vehicle.VehicleMake,
        VehicleModel: vehicle.VehicleModel,
        VehicleYear: vehicle.VehicleYear,
        VehicleColour: vehicle.VehicleColour,
        VehicleLength: vehicle.VehicleLength,
        VehicleWidth: vehicle.VehicleWidth,
        VehicleHeight: vehicle.VehicleHeight,
        VehicleWeight: vehicle.VehicleWeight,
        VehicleIsNew: vehicle.VehicleIsNew,
        VehicleValue: vehicle.VehicleValue,
        HasValidMOT: null,
        IsReturnVehicle: vehicle.IsReturnVehicle,
        IsSegmentVehicle: vehicle.IsSegmentVehicle,
        SegmentIndex: vehicle.SegmentIndex,
        FuelTypeID: vehicle.FuelTypeID,
      }

      newVehiclesValues.push(newVehicle);
    });

    const payload: AcceptQuoteModel = {
      QuoteID: acceptedQuoteId,
      Vehicles: newVehiclesValues,
    };

    putAcceptQuote(payload);
  }, [acceptedQuoteId, vehicleListForAcceptance, onAcceptModalClose, putAcceptQuote]);

  const onDelete = useCallback((quoteId: number) => {
    setDeletedQuoteId(quoteId)
    setShowQuoteDeletedModal(true);
  }, []);

  const onDeleteModalClose = useCallback(() => {
    setDeletedQuoteId(0);
    setShowQuoteDeletedModal(false);
  }, [])

  const onDeleteSubmit = useCallback(() => {
    onDeleteModalClose();

    const payload: DeleteQuoteModel = { quoteID: deletedQuoteId };
    deleteQuote(payload);
  }, [
    deletedQuoteId,
    deleteQuote,
    onDeleteModalClose
  ]);

  const onUpdateModalClose = useCallback(() => {
    setUpdateQuoteMarginRequest(undefined);
    setShowUpdateQuoteMarginModal(false);
  }, []);

  const onUpdate = useCallback((quoteID: number, requestingMemberFee: number) => {
    setUpdateQuoteMarginRequest({ quoteID, requestingMemberFee });
    setShowUpdateQuoteMarginModal(true);
  }, []);

  const onUpdateSubmit = useCallback(() => {
    onUpdateModalClose();
    if (updateQuoteMarginRequest) {
      putUpdateQuoteMargin(updateQuoteMarginRequest);
    }
  }, [updateQuoteMarginRequest, onUpdateModalClose, putUpdateQuoteMargin]);

  const isLoading = isGetJobLoading || isLoadingAcceptQuote || isLoadingQuoteDeleted || isLoadingUpdateQuoteMargin;

  return (
    <>
      <FormikProvider value={formik}>
        <Alert {...alert} />
        <fieldset>
          <br />
          <ConfirmDialog
            header="Quote Is Higher Than Fixed Maximum"
            body="Please confirm that you have approval for this."
            show={showQuoteHigherModal}
            cancelCallback={() => {
              setAcceptedQuoteId(0);
              setShowQuoteHigherModal(false);
            }}
            confirmCallback={() => {
              setShowQuoteHigherModal(false);
              setShowAcceptQuoteModal(true);
            }}
          />
          <ModalDialog
            body={<VehiclesModule
              func={(updatedVehiclesFromModule: any) => setVehicleListForAcceptance(updatedVehiclesFromModule)}
              vehicles={vehicles}
            />}
            footer={<ModalFooter formik={formik} onClose={onAcceptModalClose} onSubmit={onAcceptSubmit} />}
            header="Accept"
            show={showAcceptQuoteModal}
            closeCallback={onAcceptModalClose}
          />
          <ModalDialog
            body={ConfirmDialogContent.DeleteMarkQuote.Content}
            footer={<ModalFooter formik={formik} onClose={onDeleteModalClose} onSubmit={onDeleteSubmit} />}
            header={ConfirmDialogContent.DeleteMarkQuote.Title}
            show={showQuoteDeletedModal}
            closeCallback={onDeleteModalClose}
          />
          <ModalDialog
            body='Are you sure?'
            footer={<ModalFooter formik={formik} onClose={onUpdateModalClose} onSubmit={onUpdateSubmit} />}
            header='Update Quote Margin'
            show={showUpdateQuoteMarginModal}
            closeCallback={onUpdateModalClose}
          />
          <FieldSetHeader>
            Placed Quotes / Accepted Fixed Prices
          </FieldSetHeader>
          <table id='id_table_placed_quotes_accepted_fixed_prices' className="table table-bordered table-condensed table-striped">
            <thead>
              <tr>
                <td></td>
                <td>Supplier</td>
                <td>Type</td>
                <td>Price</td>
                <td>Fuel</td>
                <td>Dealer Pays</td>
                <td>Margin</td>
                <td>Collect</td>
                <td>Deliver</td>
                {(jobDetails.isJobPendingQuote || jobDetails.isJobBookedPrice) && (
                  <td>Expiry</td>
                )}
                <td>Note</td>
                <td>Status</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {isLoading && <tr>Loading...</tr>}
              {quotes.map((quote, index) => <PlacedQuoteRow
                key={index}
                index={index}
                isLoadingAcceptQuote={isLoadingAcceptQuote}
                isLoadingQuoteDeleted={isLoadingQuoteDeleted}
                isLoadingUpdateQuoteMargin={isLoadingUpdateQuoteMargin}
                jobDetails={jobDetails}
                memberList={memberList}
                quote={quote}
                onAccept={onAccept}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />)}
            </tbody>
          </table>
        </fieldset>
      </FormikProvider>
    </>
  );
}