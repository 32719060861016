import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../FormControls/Button';
import './index.css';

export type ConfirmDialogProps = {
  header: any;
  body: any;
  show?: boolean;
  cancelCallback: () => void;
  confirmCallback?: Function;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (
  props
): JSX.Element => {
  const { header, body, show = false, cancelCallback, confirmCallback } = props;

  return (
    <Modal
      animation={false}
      style={{ opacity: 1 }}
      show={show}
      onHide={cancelCallback}
    >
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={cancelCallback}
          label="Close"
        ></Button>
        <Button
          variant="secondary"
          onClick={confirmCallback}
          label="Ok"
        ></Button>
      </Modal.Footer>
    </Modal>
  );
};
