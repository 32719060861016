import { useMutation, UseMutationResult } from "react-query";
import { EntityCreatedOrUpdatedViewModel } from "../Types";
import { ChangePasswordRequest } from "../Models/Members/EditMember/ChangePasswordRequest";
import agent from "../Axios/Agent";

export const usePutUpdateAdminPassword = (options?: any): UseMutationResult<EntityCreatedOrUpdatedViewModel, any, ChangePasswordRequest> => {
    const changePassword = (payload: ChangePasswordRequest) => {
         return agent.admin.putUpdateAdminPassword(payload);
    };

    return useMutation(changePassword);
};