import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { DrivenServiceIcon, TransportedServiceIcon } from "../../../../../../Assets/Images";
import { FormControlKeys } from "../../../../../../Components/FormControls";
import { HoverableLink } from "../../../../../../Components/Formik";
import FormikControl from "../../../../../../Components/Formik/FormikControl";
import { SpanItem, SupplierLabelInfo } from "../../../../../../Components/UI";
import { QuoteModel } from "../../../../../../Models/Jobs/EditJob";
import { DropdownMember } from "../../../../../../Models/Members";
import { RouteConstants } from "../../../../../../Routes";
import { getFormattedDateWithYear, isExpired, lookupEnumByVal } from "../../../../../../Utils";
import { QuoteStatus, ServiceType } from "../../../../../../Utils/Enums";
import { QuoteStatusOptions } from "../../../../Members/Constants";
import { PlacedQuotes } from "../../Types";
import { RoundToPlaces, calcDealerAmount, calcDealerAmountChanged, calcDealerFeeOriginal } from "../CalcFunctions";
import { getUsernameById } from "../helper";
import QuoteExpirationCell from "./QuoteExpirationCell";
import { PlacedQuoteJobDetails } from "../Models/PlacedQuoteJobDetails";

interface PlacedQuoteRowProps {
    index: number;
    isLoadingAcceptQuote: boolean;
    isLoadingQuoteDeleted: boolean;
    isLoadingUpdateQuoteMargin: boolean;
    jobDetails: PlacedQuoteJobDetails;
    memberList: DropdownMember[];
    quote: QuoteModel;
    onAccept: (quoteId: number, additionalNotesRead: boolean | undefined, quoteType?: ServiceType, quoteAmount?: number) => void;
    onDelete: (quoteId: number) => void;
    onUpdate: (quoteId: number, dealerFee: number) => void;
}

export default function PlacedQuoteRow({
    index,
    isLoadingAcceptQuote,
    isLoadingQuoteDeleted,
    isLoadingUpdateQuoteMargin,
    jobDetails,
    memberList,
    quote,
    onAccept,
    onDelete,
    onUpdate,
}: PlacedQuoteRowProps) {
    const {
        AdditionalNotes,
        Amount,
        CollectionDate,
        DeliveryDate,
        ExpirationDate,
        ExpirationPeriod,
        Id,
        QuoteStatus: Status,
        QuoteType,
        RequestingMemberDiscount,
        RequestingMemberFee,
        RequestingMemberFeeDeferred,
        RequestingMemberFeeOriginal,
        RequestingMemberFeeDeferredOriginal,
        SupplyingMemberId,
        VATAdditional,
    } = quote;

    const {
        isFuelInclusive,
        isFuelRequired,
        isJobBookedPrice,
        isJobCompletedPrice,
        isJobFeeDeferred,
        isJobGuaranteedPrice,
        isJobPaymentPrePayMethod,
        isJobPendingQuote,
    } = jobDetails;

    const [additionalNotesRead, setAdditionalNotesRead] = useState(false);

    const [dealerAmount, setDealerAmount] = useState<number>(0);
    const [dealerFee, setDealerFee] = useState<number>(0)
    const [dealerFeeOriginal, setDealerFeeOriginal] = useState<number>(0)
    const [dealerAmountChanged, setDealerAmountChanged] = useState<boolean>(false);
    const [requestingMemberFeeDeferred] = useState(Number(RequestingMemberFeeDeferred));

    const dealerAmountOriginal = RoundToPlaces(Amount + (isJobFeeDeferred ? 0 : RequestingMemberFee), 2);

    useEffect(() => {
        const requestingMemberFeeDeferredOriginal = RequestingMemberFeeDeferredOriginal ?? 0;

        const dealerFee = calcDealerAmount(isJobFeeDeferred, dealerAmountOriginal, requestingMemberFeeDeferred, Amount);
        const dealerFeeOriginal = calcDealerFeeOriginal(isJobFeeDeferred, requestingMemberFeeDeferredOriginal, RequestingMemberFeeOriginal);

        setDealerAmount(dealerAmountOriginal);
        setDealerFee(dealerFee);
        setDealerFeeOriginal(dealerFeeOriginal);
    }, [
        Amount,
        dealerAmountOriginal,
        isJobFeeDeferred,
        RequestingMemberFee,
        requestingMemberFeeDeferred,
        RequestingMemberFeeDeferredOriginal,
        RequestingMemberFeeOriginal,
    ]);

    useEffect(() => {
        const dealerAmountChanged = calcDealerAmountChanged(dealerAmount, dealerAmountOriginal);
        setDealerAmountChanged(dealerAmountChanged);
    }, [dealerAmount, dealerAmountOriginal]);

    const hasAdditionalNotes = AdditionalNotes && AdditionalNotes?.length > 1;
    const isLoading = isLoadingAcceptQuote || isLoadingQuoteDeleted || isLoadingUpdateQuoteMargin;

    return (
        <tr key={Id}>
            <td>{++index}</td>
            <td>
                <HoverableLink to={`${RouteConstants.EditMember}/${SupplyingMemberId}`} target="_blank">
                    <SupplierLabelInfo>
                        {getUsernameById(memberList, SupplyingMemberId.toString())}
                    </SupplierLabelInfo>
                </HoverableLink>
            </td>
            <td>
                {QuoteType === ServiceType.Driven && (
                    <Tooltip title="Driven">
                        <img alt="Driven" src={DrivenServiceIcon} />
                    </Tooltip>
                )}
                {QuoteType === ServiceType.Transported && (
                    <Tooltip title="Transported">
                        <img
                            alt="Transported"
                            src={TransportedServiceIcon}
                        />
                    </Tooltip>
                )}
            </td>
            <td>
                <span title="Transporter Quote">
                    {priceInPoundsPlusVat(Amount, VATAdditional)}
                </span>
                <br />
                <span title="Dealer Price" style={{ color: "red" }}>
                    {priceInPoundsPlusVat(Amount + RequestingMemberFee, VATAdditional)}
                </span>

                {requestingMemberFeeDeferred !== 0 && (
                    <>
                        <br />
                        <span title="Dealer Price (including deferred fee)" style={{ color: "red", fontWeight: "bold" }}>
                            {priceInPoundsPlusVat(Amount + requestingMemberFeeDeferred, VATAdditional)}
                        </span>
                    </>
                )}
                {RequestingMemberDiscount !== 0 && (
                    <div>
                        <br />
                        <span title="Dealer Discounted Price">
                            {priceInPoundsPlusVat(
                                Amount + RequestingMemberFee - (RequestingMemberDiscount ?? 0),
                                VATAdditional
                            )}
                        </span>
                    </div>
                )}
            </td>
            <td>
                {isFuelInclusive && (
                    <span>
                        {isFuelRequired ? "Incl Fuel" : "Not Required"}
                    </span>
                )}
                {!isFuelInclusive && (
                    <>
                        <br />
                        <span>
                            {isFuelRequired ? "Included" : "Excluded"}
                        </span>
                    </>
                )}
            </td>
            <td>
                <FormikControl
                    control={FormControlKeys.InputGroup}
                    disabled={isJobBookedPrice || isJobCompletedPrice || isJobGuaranteedPrice || isLoading}
                    min={0}
                    name={PlacedQuotes.DealerPays + Id}
                    style={{ maxWidth: "100px" }}
                    text="£"
                    value={dealerAmount}
                    onChange={(e: any) => {
                        const { value } = e.target;

                        const newDealerFee = calcDealerAmount(
                            isJobFeeDeferred,
                            value,
                            requestingMemberFeeDeferred,
                            Amount);

                        setDealerAmount(value);
                        setDealerFee(newDealerFee);
                    }}
                />
            </td>
            <td>
                <span> {"£" + dealerFee.toFixed(2)} </span>
                {(dealerFee !== dealerFeeOriginal && !!dealerFeeOriginal) && (
                    <>
                        <br />
                        <span title="Original Margin" style={{ color: "red" }}>
                            {" "}
                            {"£" + dealerFeeOriginal.toFixed(2)}{" "}
                        </span>
                    </>
                )}
            </td>
            <td>
                <SpanItem>
                    {getFormattedDateWithYear(CollectionDate)}
                </SpanItem>
            </td>
            <td>
                <SpanItem>
                    {getFormattedDateWithYear(DeliveryDate)}
                </SpanItem>
            </td>
            {(isJobPendingQuote || isJobBookedPrice) && (
                <td>
                    <QuoteExpirationCell
                        expirationDate={ExpirationDate}
                        expirationPeriod={ExpirationPeriod}
                        status={Status}
                    />
                </td>
            )}
            <td>
                {hasAdditionalNotes && (
                    <>
                        <Tooltip title={AdditionalNotes}>
                            <label>View Mark</label>
                        </Tooltip>
                        {Status === QuoteStatus.Placed && (
                            <FormikControl
                                control={FormControlKeys.CheckboxReversed}
                                disabled={isLoading}
                                label="Mark Read"
                                name={PlacedQuotes.MarkRead + index}
                                size="sm"
                                onClick={() => setAdditionalNotesRead(!additionalNotesRead)}
                            />
                        )}
                    </>
                )}
            </td>
            <td>
                {lookupEnumByVal(QuoteStatusOptions, Status.toString())}
            </td>
            <td>
                {Status === QuoteStatus.Placed && (
                    <>
                        {!isJobGuaranteedPrice && (
                            <>
                                {(isJobPaymentPrePayMethod || dealerAmountChanged) && (
                                    <FormikControl
                                        control={FormControlKeys.Button}
                                        disabled={isLoading}
                                        label={isLoadingUpdateQuoteMargin ? "Loading..." : "Update"}
                                        size="sm"
                                        onClick={() => onUpdate(Id, dealerFee)}
                                    />
                                )}
                                {!isJobPaymentPrePayMethod && !isExpired(ExpirationDate) && !dealerAmountChanged && (
                                    <FormikControl
                                        control={FormControlKeys.Button}
                                        disabled={isLoading}
                                        label={isLoadingAcceptQuote ? "Loading..." : "Accept"}
                                        size="sm"
                                        variant="success"
                                        onClick={() => {
                                            let userHasReadAddtionalNotes = undefined;

                                            if (hasAdditionalNotes) {
                                                userHasReadAddtionalNotes = additionalNotesRead;
                                            }

                                            onAccept(Id, userHasReadAddtionalNotes);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {isJobGuaranteedPrice && !isExpired(ExpirationDate) && (
                            <FormikControl
                                control={FormControlKeys.Button}
                                disabled={isLoading}
                                label={isLoadingAcceptQuote ? "Loading..." : "Accept"}
                                size="sm"
                                variant="success"
                                onClick={() => {
                                    let userHasReadAddtionalNotes = undefined;

                                    if (hasAdditionalNotes) {
                                        userHasReadAddtionalNotes = additionalNotesRead;
                                    }

                                    onAccept(Id, userHasReadAddtionalNotes, QuoteType, Amount);
                                }}
                            />
                        )}
                    </>
                )}
                {(Status === QuoteStatus.Placed ||
                    Status === QuoteStatus.Accepted) && (
                        <FormikControl
                            control={FormControlKeys.Button}
                            disabled={isLoading}
                            label={isLoadingQuoteDeleted ? "Loading..." : "Delete"}
                            size="sm"
                            variant="danger"
                            onClick={() => onDelete(Id)}
                        />
                    )}
                {Status === QuoteStatus.Accepted && (
                    <>
                        <FormikControl
                            control={FormControlKeys.Button}
                            label="CONFIRM"
                            size="sm"
                            onClick={() => { }}
                        ></FormikControl>
                        <FormikControl
                            control={FormControlKeys.Button}
                            label="DECLINE"
                            size="sm"
                            onClick={() => { }}
                        ></FormikControl>
                    </>
                )}
            </td>
        </tr>
    );
}

function priceInPoundsPlusVat(value: number, VATAdditional: boolean | undefined) {
    return `£${value.toFixed(2)}${VATAdditional ? " + VAT" : ""}`
}