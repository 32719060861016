import { useMutation, UseMutationResult } from 'react-query';
import agent from '../../../../../Axios/Agent';
import { UpdateQuoteMarginRequest } from '../../../../../Models/Jobs/EditJob/UpdateQuoteMarginRequest';
import { EntityCreatedOrUpdatedViewModel, MovexErrorResponse } from '../../../../../Types';

export const usePutUpdateQuoteMargin = (
  options?: any
): UseMutationResult<EntityCreatedOrUpdatedViewModel, MovexErrorResponse, UpdateQuoteMarginRequest> => {

  const putUpdateQuoteMargin = (request: UpdateQuoteMarginRequest) => {
    return agent.jobs.putUpdateQuoteMargin(request);
  };

  return useMutation(putUpdateQuoteMargin);
};
