import { FormikProvider, useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { object, ref, string } from "yup";
import { usePutUpdateAdminPassword } from "../../Hooks/usePutChangePassword";
import { ChangePasswordRequest } from "../../Models/Members/EditMember/ChangePasswordRequest";
import { Alert } from "../Alert";
import { useAlert } from "../Alert/useAlert";
import { FormControlKeys } from "../FormControls";
import Button from '../FormControls/Button';
import { FormContainer } from "../Formik";
import FormikControl from "../Formik/FormikControl";

interface ChangePasswordModalProps {
    show: boolean;
    onClose: () => void;
}

const ChangePasswordKeys = {
    CurrentPassword: "CurrentPassword",
    NewPassword: "NewPassword",
    ConfirmNewPassword: "ConfirmNewPassword",
}

const validationSchema = object({
    [ChangePasswordKeys.CurrentPassword]: string().required('Current password is required'),
    [ChangePasswordKeys.NewPassword]: string()
        .required('New password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"£$%^&*() _+[\]{};:'@#~\\|,<.>/?_=\-+]).{8,}$/,
            "Must be 8+ and contain 1 lower, 1 upper, 1 digit, and 1 from: !\"£$%^&*() _+[]{{}};:'@#~\\|,<.>/?_-=+"
        ),
    [ChangePasswordKeys.ConfirmNewPassword]: string()
        .required('Confirm new password is required')
        .oneOf([ref(ChangePasswordKeys.NewPassword)], 'Passwords must match')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"£$%^&*() _+[\]{};:'@#~\\|,<.>/?_=\-+]).{8,}$/,
            "Must be 8+ and contain 1 lower, 1 upper, 1 digit, and 1 from: !\"£$%^&*() _+[]{{}};:'@#~\\|,<.>/?_-=+"
        ),
});

export default function ChangePasswordModal({ show, onClose }: ChangePasswordModalProps) {
    const {
        error,
        isLoading,
        isSuccess,
        reset,
        mutate,
    } = usePutUpdateAdminPassword();

    const formik = useFormik<ChangePasswordRequest>({
        initialValues: {
            CurrentPassword: '',
            NewPassword: '',
            ConfirmNewPassword: '',
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => mutate(values),
    });

    const { handleSubmit } = formik;
    const { alert, setAlert, handleAlertClose } = useAlert();

    const handleClose = useCallback(() => {
        onClose();
        formik.resetForm();
    }, [formik, onClose])

    useEffect(() => {
        if (!alert.show) {
            if (error?.error) {
                handleClose();
                setAlert({
                    ...alert,
                    show: true,
                    header: "Unknown error",
                    body: error.error.toString(),
                    closeCallback: () => {
                        reset();
                        handleAlertClose();
                    },
                });
            } else if (error?.Description) {
                handleClose();
                setAlert({
                    ...alert,
                    show: true,
                    header: "Unknown error",
                    body: error.Description,
                    closeCallback: () => {
                        reset();
                        handleAlertClose();
                    },
                });
            }
        }
    }, [alert, error, handleAlertClose, handleClose, reset, setAlert]);

    useEffect(() => {
        if (isSuccess && !alert.show) {
            handleClose();
            setAlert({
                ...alert,
                show: true,
                header: 'Password Updated!',
                body: 'Your password was updated successfully!',
                closeCallback: () => {
                    reset();
                    handleAlertClose();
                },
            });
        }
    }, [alert, isSuccess, handleAlertClose, handleClose, reset, setAlert]);

    return (
        <>
            <Alert {...alert} />
            <Modal
                animation={false}
                show={show}
                style={{ opacity: 1 }}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <FormikProvider value={formik}>
                    <FormContainer onSubmit={
                        (e) => {
                            e.preventDefault();

                            handleSubmit(e);
                        }}>
                        <Modal.Body>
                            <FormikControl
                                label='Current Password'
                                name={ChangePasswordKeys.CurrentPassword}
                                readOnly={isLoading || isSuccess}
                                showRequired
                                type='password'
                            />
                            <FormikControl
                                label='New Password'
                                name={ChangePasswordKeys.NewPassword}
                                readOnly={isLoading || isSuccess}
                                showRequired
                                type='password'
                            />
                            <FormikControl
                                label='Confirm New Password'
                                name={ChangePasswordKeys.ConfirmNewPassword}
                                readOnly={isLoading || isSuccess}
                                showRequired
                                type='password'
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                label="Close"
                            />
                            <FormikControl
                                control={FormControlKeys.Button}
                                name='SubmitBtn'
                                type='submit'
                                className='btn btn-primary f-r'
                                label="Submit"
                            />
                        </Modal.Footer>
                    </FormContainer>
                </FormikProvider>
            </Modal>
        </>
    )
}