import { getFuelEstimatedCostPerMile } from "../../../../../RootNavigator";

export function RoundToPlaces(value: number, places: number) {
  var divisor = Math.pow(10.0, Number(places));
  return Math.round(value * divisor) / divisor;
}

/** Place */
export async function fuelEstimate(noOfVehicles: number, miles: number) {
  var minPrice = 5;
  const fuelEstimateCostPerMile = await getFuelEstimatedCostPerMile().then(res => res);
  var price = Math.round(Number(noOfVehicles * miles * fuelEstimateCostPerMile));
  return Math.max(minPrice, price);
}

export const estimateFuel = async (noOfVehicles: number, distanceMiles: number) => {
  let minPrice = 5;
  let price = await fuelEstimate(noOfVehicles, distanceMiles);
  return Math.max(minPrice, price);
};

/**Section Placed Quotes / Accepted Fixed Prices */
export const calcDealerAmount = function (
  feesDeferred: boolean,
  dealerAmount: number,
  requestingMemberFeeDeferred: number,
  quoteAmount: number
) {
  const amount = feesDeferred
    ? dealerAmount + requestingMemberFeeDeferred
    : dealerAmount;

  return RoundToPlaces(amount - quoteAmount, 2);
};

export const calcDealerFeeOriginal = (
  feesDeferred: boolean,
  requestingMemberFeeDeferredOriginal: number,
  requestingMemberFeeOriginal?: number | null
): number => {
  return feesDeferred
    ? requestingMemberFeeDeferredOriginal
    : requestingMemberFeeOriginal ?? 0;
};

export const calcDealerAmountChanged = (
  dealerAmount: number,
  dealerAmountOriginal: number
) => {
  return dealerAmount !== dealerAmountOriginal;
};
