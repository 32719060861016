/** Inbuilt libraries */
import React, { useCallback, useEffect, useState } from "react";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, NavLink, useLocation } from "react-router-dom";
/** Custom Components */
import { Logo } from "../../Assets/Images";
import { SessionStorageKeys, useSessionStorage } from "../../Hooks";
import * as Storage from "../../Utils/Storage";
import ChangePasswordModal from "./ChangePasswordModal";
import {
  NavbarMenus,
  NavbarMenusRoutes,
  SystemNavbarSubMenus,
} from "./Constants";
import "./index.css";

const TopNav: React.FC<any> = (props) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(NavbarMenus.Members);
  const [_, __, deleteSessionStorageViewJobsFilters] = useSessionStorage(SessionStorageKeys.ViewJobsPageSearchFilter);

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const path = splitLocation[1];
  const loggedinDisplayName = Storage.getAdminDisplayName();
  const userName = `Welcome ${loggedinDisplayName}`;

  /** For small screens only, it will close the navbar automatically after click */
  const [expanded, setExpanded] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const toggleNavbar = useCallback(() => setExpanded((prevExpanded) => !prevExpanded), []);

  useEffect(() => {
    if (path) {
      switch (path) {
        case ViewTasksRoute:
        case AddTaskRoute:
        case EditTaskRoute:
          setActiveMenu(NavbarMenus.Tasks);
          break;
        case NewMembersRoute:
        case MembersRoute:
        case AddMemberRoute:
          setActiveMenu(NavbarMenus.Members);
          break;
        case ViewGroupsRoute:
        case AddGroupRoute:
        case EditGroupRoute:
          setActiveMenu(NavbarMenus.Groups);
          break;
        case CustomerSetMarginMaintenanceRoute:
          setActiveMenu(NavbarMenus.CustomerSet);
          break;
        case ViewJobsRoute:
        case ViewPaymentsRoute:
        case JobActivityHistoryRoute:
        case ViewJobRoute:
          setActiveMenu(NavbarMenus.Jobs);
          break;
        case ViewFeedbackRoute:
        case AddFeedbackRoute:
        case EditFeedbackRoute:
          setActiveMenu(NavbarMenus.Feedback);
          break;
        case ViewSearchesRoute:
        case AddSearchRoute:
        case EditSearchRoute:
          setActiveMenu(NavbarMenus.Searches);
          break;
        case ViewPartnersRoute:
        case AddPartnerRoute:
        case EditPartnerRoute:
          setActiveMenu(NavbarMenus.Partners);
          break;
        case ViewPricesRoute:
        case ViewPriceGroupsRoute:
        case AddPriceGroupRoute:
        case DefaultMarginRoute:
        case EditPriceGroupRoute:
          setActiveMenu(NavbarMenus.Prices);
          break;
        case ViewBillsRoute:
        case ViewInvoicesRoute:
        case AddInvoiceRoute:
        case AddCreditNoteRoute:
        case ViewBillRoute:
          setActiveMenu(NavbarMenus.Billing);
          break;
        case JobsByUserRoute:
        case NewUpdatedUsersRoute:
        case StatisticsRoute:
          setActiveMenu(NavbarMenus.Reporting);
          break;
        case ViewAdministratorsRoute:
        case EditAdminRoleRoute:
        case ViewAdminRolesRoute:
        case AddAdministratorRoute:
        case EditAdministratorRoute:
        case AddAdminRoleRoute:
        case PagesRoute:
        case AuthServiceRoute:
        case CacheServiceRoute:
        case EmailDeliveryServiceRoute:
        case SchedulingServiceRoute:
        case ViewVehiclesRoute:
        case EditVehicleRoute:
        case AddVehicleRoute:
        case TasksRoute:
          setActiveMenu(NavbarMenus.System);
          break;
        default:
          setActiveMenu(NavbarMenus.Members);
      }
    }
  }, [path]);

  const {
    /**Tasks */
    ViewTasksRoute,
    AddTaskRoute,
    EditTaskRoute,
    /** Members */
    NewMembersRoute,
    MembersRoute,
    AddMemberRoute,
    /** Groups */
    ViewGroupsRoute,
    AddGroupRoute,
    EditGroupRoute,
    /** Customer Set */
    CustomerSetMarginMaintenanceRoute,
    /* Jobs */
    ViewJobsRoute,
    ViewJobRoute,
    ViewPaymentsRoute,
    JobActivityHistoryRoute,
    /* Feedback */
    ViewFeedbackRoute,
    AddFeedbackRoute,
    EditFeedbackRoute,
    /* Searches */
    ViewSearchesRoute,
    AddSearchRoute,
    EditSearchRoute,
    /* Partners */
    ViewPartnersRoute,
    AddPartnerRoute,
    EditPartnerRoute,
    /** Prices */
    ViewPricesRoute,
    ViewPriceGroupsRoute,
    AddPriceGroupRoute,
    DefaultMarginRoute,
    EditPriceGroupRoute,
    /** Billing */
    ViewBillsRoute,
    ViewInvoicesRoute,
    AddInvoiceRoute,
    AddCreditNoteRoute,
    ViewBillRoute,
    /** Reporting */
    JobsByUserRoute,
    NewUpdatedUsersRoute,
    StatisticsRoute,
    /** System */
    ViewAdministratorsRoute,
    ViewAdminRolesRoute,
    AddAdministratorRoute,
    EditAdministratorRoute,
    AddAdminRoleRoute,
    PagesRoute,
    AuthServiceRoute,
    CacheServiceRoute,
    EmailDeliveryServiceRoute,
    SchedulingServiceRoute,
    TasksRoute,
    ViewVehiclesRoute,
    EditVehicleRoute,
    AddVehicleRoute,
    LogoutRoute,
    EditAdminRoleRoute,
  } = NavbarMenusRoutes;

  const onClick = useCallback((menu: string) => {
    setActiveMenu(menu);
    setExpanded(false);

    if (menu !== NavbarMenus.Jobs) {
      deleteSessionStorageViewJobsFilters();
    }
  }, [deleteSessionStorageViewJobsFilters]);

  const getActiveClassName = useCallback(
    (navbarMenuTask: string) => activeMenu === navbarMenuTask ? 'active' : 'inactive',
    [activeMenu]
  );

  return (
    <Navbar id="mainNavbar" bg="light" expand="lg" fixed="top" expanded={expanded}>
      <Nav className="container-fluid">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto navbbar">
            <Navbar.Brand>
              <Nav.Link
                as={Link}
                to={`/${NewMembersRoute}`}
                onClick={() => onClick(NavbarMenus.Members)}
              >
                <img alt="Movex Logo" src={Logo} />
              </Nav.Link>
            </Navbar.Brand>

            {/* Tasks */}
            <NavDropdown
              title={NavbarMenus.Tasks}
              id="basic-nav-dropdown"
              className={getActiveClassName(NavbarMenus.Tasks)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewTasksRoute}`}
                onClick={() => onClick(NavbarMenus.Tasks)}
              >
                View Tasks
              </NavDropdown.Item>
              {<NavDropdown.Item
                as={NavLink}
                to={`/${AddTaskRoute}`}
                onClick={() => onClick(NavbarMenus.Tasks)}
              >
                Create New Task
              </NavDropdown.Item>}
            </NavDropdown>

            {/* Members */}
            <NavDropdown
              title={NavbarMenus.Members}
              className={getActiveClassName(NavbarMenus.Members)}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${NewMembersRoute}`}
                onClick={() => onClick(NavbarMenus.Members)}
              >
                New Members
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${MembersRoute}`}
                onClick={() => onClick(NavbarMenus.Members)}
              >
                View Members
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddMemberRoute}`}
                onClick={() => onClick(NavbarMenus.Members)}
              >
                Add Member
              </NavDropdown.Item>
            </NavDropdown>

            {/* Groups */}
            <NavDropdown
              title={NavbarMenus.Groups}
              id="basic-nav-dropdown"
              className={getActiveClassName(NavbarMenus.Groups)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewGroupsRoute}`}
                onClick={() => onClick(NavbarMenus.Groups)}
              >
                View Groups
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddGroupRoute}`}
                onClick={() => onClick(NavbarMenus.Groups)}
              >
                Add Group
              </NavDropdown.Item>
            </NavDropdown>

            {/* Customer Set */}
            <NavDropdown
              title={NavbarMenus.CustomerSet}
              id="basic-nav-dropdown"
              className={getActiveClassName(NavbarMenus.CustomerSet)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${CustomerSetMarginMaintenanceRoute}`}
                onClick={() => onClick(NavbarMenus.CustomerSet)}
              >
                Margins
              </NavDropdown.Item>
            </NavDropdown>

            {/* Jobs */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Jobs}
              className={getActiveClassName(NavbarMenus.Jobs)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewJobsRoute}`}
                onClick={() => onClick(NavbarMenus.Jobs)}
              >
                View Jobs
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewPaymentsRoute}`}
                onClick={() => onClick(NavbarMenus.Jobs)}
              >
                View Payments
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${JobActivityHistoryRoute}`}
                onClick={() => onClick(NavbarMenus.Jobs)}
              >
                Job Activity History
              </NavDropdown.Item>
            </NavDropdown>

            {/* Feedback */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Feedback}
              className={getActiveClassName(NavbarMenus.Feedback)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewFeedbackRoute}`}
                onClick={() => onClick(NavbarMenus.Feedback)}
              >
                View Feedback
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddFeedbackRoute}`}
                onClick={() => onClick(NavbarMenus.Feedback)}
              >
                Add Feedback
              </NavDropdown.Item>
            </NavDropdown>

            {/* Searches */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Searches}
              className={getActiveClassName(NavbarMenus.Searches)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewSearchesRoute}`}
                onClick={() => onClick(NavbarMenus.Searches)}
              >
                View Searches
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddSearchRoute}`}
                onClick={() => onClick(NavbarMenus.Searches)}
              >
                Add Search
              </NavDropdown.Item>
            </NavDropdown>

            {/* Partners */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Partners}
              className={getActiveClassName(NavbarMenus.Partners)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewPartnersRoute}`}
                onClick={() => onClick(NavbarMenus.Partners)}
              >
                View Partners
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddPartnerRoute}`}
                onClick={() => onClick(NavbarMenus.Partners)}
              >
                Add Partner
              </NavDropdown.Item>
            </NavDropdown>

            {/* Prices */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Prices}
              className={getActiveClassName(NavbarMenus.Prices)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewPricesRoute}`}
                onClick={() => onClick(NavbarMenus.Prices)}
              >
                View Prices
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewPriceGroupsRoute}`}
                onClick={() => onClick(NavbarMenus.Prices)}
              >
                View Price Group
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddPriceGroupRoute}`}
                onClick={() => onClick(NavbarMenus.Prices)}
              >
                Add Price Group
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${DefaultMarginRoute}`}
                onClick={() => onClick(NavbarMenus.Prices)}
              >
                Default Margin
              </NavDropdown.Item>
            </NavDropdown>

            {/* Billing */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Billing}
              className={getActiveClassName(NavbarMenus.Billing)}
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewBillsRoute}`}
                onClick={() => onClick(NavbarMenus.Billing)}
              >
                View Bills
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${ViewInvoicesRoute}`}
                onClick={() => onClick(NavbarMenus.Billing)}
              >
                View Invoices
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddInvoiceRoute}`}
                onClick={() => onClick(NavbarMenus.Billing)}
              >
                Add Invoice
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${AddCreditNoteRoute}`}
                onClick={() => onClick(NavbarMenus.Billing)}
              >
                Add Credit Note
              </NavDropdown.Item>
            </NavDropdown>

            {/* Reporting */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.Reporting}
              className={getActiveClassName(NavbarMenus.Reporting)}
            >
              <NavDropdown.Item
                as="a"
                href="https://tableau.coxauto.co.uk/#/signin?redirect=%2fsite%2fMovex%2fviews%2fMovexWebsiteDashboard%2fDashboard1%3F%253Aembed%3Dn%26%253Aorigin%3Dviz_share_link"
                target="_blank"
                onClick={() => onClick(NavbarMenus.Reporting)}
              >
                Jobs by User
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                to={`/${NewUpdatedUsersRoute}`}
                onClick={() => onClick(NavbarMenus.Reporting)}
              >
                New or updated users
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={NavLink}
                to={`/${StatisticsRoute}`}
                onClick={() => onClick(NavbarMenus.Reporting)}
              >
                Statistics
              </NavDropdown.Item>
            </NavDropdown>

            {/* System */}
            <NavDropdown
              id="basic-nav-dropdown"
              title={NavbarMenus.System}
              className={getActiveClassName(NavbarMenus.System)}
            >
              {/* Start of  Administrators Submenu  */}
              <DropdownSubmenu
                id="basic-nav-dropdown"
                title={SystemNavbarSubMenus.Administrators}
              >
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${ViewAdministratorsRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  View Administrators
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${ViewAdminRolesRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  View Admin Roles
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${AddAdministratorRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Add Administrator
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${AddAdminRoleRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Add Admin Role
                </NavDropdown.Item>
              </DropdownSubmenu>
              {/* End  of  Administrators Submenu  */}
              <NavDropdown.Item
                as={NavLink}
                to={`/${PagesRoute}`}
                onClick={() => onClick(NavbarMenus.System)}
              >
                Pages
              </NavDropdown.Item>
              {/* Start of  Services Submenu  */}
              <DropdownSubmenu
                id="basic-nav-dropdown"
                title={SystemNavbarSubMenus.Services}
              >
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${AuthServiceRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Auth Services
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${CacheServiceRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Cache Service
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${EmailDeliveryServiceRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Email Service
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${SchedulingServiceRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Scheduling Service
                </NavDropdown.Item>
              </DropdownSubmenu>
              {/* End  of  Services Submenu  */}
              <NavDropdown.Item
                as={NavLink}
                to={`/${TasksRoute}`}
                onClick={() => onClick(NavbarMenus.System)}
              >
                Tasks
              </NavDropdown.Item>
              {/* Start of  Vehicles Submenu  */}
              <DropdownSubmenu
                id="basic-nav-dropdown"
                title={SystemNavbarSubMenus.Vehicles}
              >
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${ViewVehiclesRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  View Vehicles
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${AddVehicleRoute}`}
                  onClick={() => onClick(NavbarMenus.System)}
                >
                  Add Vehicle
                </NavDropdown.Item>
              </DropdownSubmenu>
              {/* End  of  Vehicles Submenu  */}
            </NavDropdown>

            <NavDropdown
              title={userName}
              id="basic-nav-dropdown"
              className="ms-auto inactive"
            >
              <NavDropdown.Item as={NavLink} to="#" onClick={() => setShowChangePasswordModal(true)}>
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={`/${LogoutRoute}`}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Nav>
      <ChangePasswordModal
        show={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      />
    </Navbar>
  );
};

export default TopNav;
