export interface PlacedQuoteJobDetails {
    isFuelInclusive: boolean;
    isFuelRequired: boolean;
    isJobBookedPrice: boolean;
    isJobCompletedPrice: boolean;
    isJobFeeDeferred: boolean;
    isJobGuaranteedPrice: boolean;
    isJobPaymentPrePayMethod: boolean;
    isJobPendingQuote: boolean;
    dFixedPriceMax: number;
    tFixedPriceMax: number;
}

export const placedQuoteJobDetailsInitialValues: PlacedQuoteJobDetails = {
    isFuelInclusive: false,
    isFuelRequired: false,
    isJobBookedPrice: false,
    isJobCompletedPrice: false,
    isJobFeeDeferred: false,
    isJobGuaranteedPrice: false,
    isJobPaymentPrePayMethod: false,
    isJobPendingQuote: false,
    dFixedPriceMax: 0,
    tFixedPriceMax: 0
}