import { useEffect } from "react";
import { SpanItem } from "../../../../../../Components/UI";
import useCountdown from "../../../../../../Hooks/useCountdown";
import { DurationString, expiresInMilliseconds, isExpired, ReFormatUkDateTimeExpiry } from "../../../../../../Utils";
import { QuoteStatus } from "../../../../../../Utils/Enums";

interface Props {
  expirationDate: string;
  expirationPeriod: number;
  status: QuoteStatus;
}

const oneHourInMilliseconds = 3600000;

export default function QuoteExpirationCell({ expirationDate, expirationPeriod, status }: Props) {
  const [expiresIn, start] = useCountdown(5000);
  const canDisplayExpiryInfo = status === QuoteStatus.Placed && !!expirationDate && expirationPeriod !== 0;

  useEffect(() => {
    if(canDisplayExpiryInfo && !isExpired(expirationDate)) {
      start(expiresInMilliseconds(expirationDate));
    }
  }, [canDisplayExpiryInfo, expirationDate, start]);
  
  return (
    <SpanItem>
      {canDisplayExpiryInfo ? (
        expiresIn <= 0 ? (
          <label style={{ color: 'red' }}>
            Expired
            <br />
            {ReFormatUkDateTimeExpiry(expirationDate)}
          </label>
        ) : (
          <span style={{ color: expiresIn < oneHourInMilliseconds ? "red" : "" }}>
            {DurationString(expiresIn) ?? "N/A"}
          </span>
        )
      ) : (
        <span>N/A</span>
      )}
    </SpanItem>
  );
};