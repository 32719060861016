import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { GetMemberResponse } from '../../../../../../Models/Members/AddMember';
import { UsefulCounts } from '../../../../../../Models/Members/AddMember/UsefulCounts';
import { useGetMember } from '../General/Hooks/useGetMember';
import { HoverableLink } from '../../../../../../Components/Formik/StyledComponents';
import { useParams } from 'react-router';
import { MemberIDParamType } from '../..';
import { MemberType } from '../../../../../../Utils/Enums';
import { RouteConstants } from '../../../../../../Routes/Constants';
import { getFormattedDateWithYear } from '../../../../../../Utils';
import { Alert, useAlert } from '../../../../../../Components';

const UsefulInfoAndQuickLinks: React.FC = () => {
  const { memberID } = useParams<MemberIDParamType>();

  /** Hooks */
  const { alert, setAlert } = useAlert();
  const { data: getMemberResponse, error: getMemberError } = useGetMember();

  /** useEffect */
  useEffect(() => {
    if (getMemberError) {
      setAlert({
        ...alert,
        show: true,
        header: getMemberError?.Subject,
        body: getMemberError?.Description,
      });
    }    
  }, [getMemberResponse]);

  if (getMemberResponse) {
    const {
      UsefulCounts: usefulCountsData,
      Member: member,
      CurrentBillIds,
    } = getMemberResponse as GetMemberResponse;

    const {
      Bills,
      NoOfBlocks,
      FeedbackBy,
      FeedbackFor,
      BlocksOfOthers,
      JobsAdded,
      JobsQuotedOn,
      JobsAddedAndDone,
      JobsWorkedOnAndDone,
      SavedSearches,
      PositiveFeedback,
    } = usefulCountsData as UsefulCounts;

    const signUpDate = getFormattedDateWithYear(
      member?.SignupDate?.toString() || ''
    );

    return (
      <div className="fw-600">
        <Alert {...alert} />
        <fieldset>
          <br />
          <legend>Useful Information & Quick Links</legend>
          <Row>
            <Col>
              <div>
                <LabelItem>Signed up:</LabelItem>
                <SpanItem>{signUpDate}</SpanItem>
              </div>
              <div>
                <LabelItem>Blocked up:</LabelItem>
                <SpanItem>{NoOfBlocks}</SpanItem>
              </div>
              <div>
                <LabelItem>Blocking Others:</LabelItem>
                <SpanItem>{BlocksOfOthers}</SpanItem>
              </div>
              <div>
                <LabelItem>Jobs U. Done:</LabelItem>
                <SpanItem>{JobsAddedAndDone}</SpanItem>
              </div>
              <div>
                <LabelItem>Jobs S. Done:</LabelItem>
                <SpanItem>{JobsWorkedOnAndDone}</SpanItem>
              </div>
              <div>
                <LabelItem>Jobs Quoted:</LabelItem>
                <SpanItem>{JobsQuotedOn}</SpanItem>
              </div>
            </Col>
            <Col>
              <div>
                <LabelItem>Jobs Placed:</LabelItem>
                <SpanItem>{JobsAdded}</SpanItem>
              </div>
              <div>
                <LabelItem>Feedback by:</LabelItem>
                <SpanItem>{FeedbackBy}</SpanItem>
              </div>
              <div>
                <LabelItem>Feedback for:</LabelItem>
                <SpanItem>{FeedbackFor}</SpanItem>
              </div>
              <div>
                <LabelItem>Positive:</LabelItem>
                <SpanItem>{PositiveFeedback}% </SpanItem>
              </div>
              <div>
                <LabelItem>Saved Searches:</LabelItem>
                <SpanItem>{SavedSearches}</SpanItem>
              </div>
              <div>
                <LabelItem>Bills:</LabelItem>
                <SpanItem>{Bills}</SpanItem>
              </div>
            </Col>
            <Col>
              <div>
                <HoverableLink
                  to={`${RouteConstants.JobsPlacedByMember}=${memberID}`}
                >
                  View Jobs Placed
                </HoverableLink>
              </div>
              <div>
                <HoverableLink
                  to={`${RouteConstants.JobActivityHistoryByMemberID}=${memberID}`}
                >
                  View Jobs Activity History
                </HoverableLink>
              </div>
              <div>
                <HoverableLink to={`${RouteConstants.FeebackBy}=${memberID}`}>
                  View Feedback By
                </HoverableLink>
              </div>
              <div>
                <HoverableLink to={`${RouteConstants.FeebackTo}=${memberID}`}>
                  View Feedback For
                </HoverableLink>
              </div>
              {member?.MemberType === MemberType.TransportCompany && (
                <div>
                  <HoverableLink
                    to={{
                      pathname: RouteConstants.ViewSearches,
                      state: { memberId: memberID }
                    }}
                  >
                    View Saved Searches
                  </HoverableLink>
                  <HoverableLink to={`${RouteConstants.AddSearch}=${memberID}`}>
                    / Add New
                  </HoverableLink>
                </div>
              )}
              <div>
                <HoverableLink
                  to={`${RouteConstants.ViewBills}/${memberID}`}
                >
                  View All Bills
                </HoverableLink>
                {CurrentBillIds?.length === 0 && (
                  <LabelItem> {'  '} / No Current</LabelItem>
                )}
                {CurrentBillIds?.length > 0 &&
                  CurrentBillIds.map((billID) => (
                    <HoverableLink
                      key={billID}
                      to={`${RouteConstants.ViewBillById}/${billID}`}
                    >
                      / Current ( {billID} )
                    </HoverableLink>
                  ))}
              </div>
            </Col>
            <Col>
              <div>
              <a href="https://portal.movex.co.uk/movex-admin/" target="_blank">Impersonate Member</a>
              </div>
            </Col>
          </Row>
        </fieldset>
      </div>
    );
  }
  return null;
};

export default UsefulInfoAndQuickLinks;

export const LabelItem = styled.label`
  display: inline-block;
  font-weight: bold;
  width: 125px;
`;
export const SpanItem = styled.span`
  display: inline-block;
`;
